import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';

import { Helmet } from 'react-helmet-async';
import RingLoader from 'react-spinners/RingLoader';

import { select_style } from '../../layouts/SelectStyle';
import AuthService from '../../services/AuthService';
import SimpleReactValidator from 'simple-react-validator';
import PlatformService from '../../services/PlatformService';
import { toast } from 'react-toastify';

const PasswordReset = () => {

    const [ email,  setEmail ] = useState("");
    const [ loading, setLoading ] = useState(false);

    const validator = useRef(new SimpleReactValidator({
        element: message => <div style={{color:'#1F0807'}}>{message}</div>
    }));;

    const navigate = useNavigate();
    const Auth = new AuthService();

    const handleRegister = () => {
        setLoading(true);
        if(!validator.current.allValid()) {
            validator.current.showMessages();
            setLoading(false);
            return;
        }
        const data = {
            email: email,
        }
        Auth.resetPassword(data).then((res) => {
            setLoading(false);
            toast.success("You successfully request your password reset.")
            navigate("/password/confirm-sent")
        })
        .catch((err) => {
            setLoading(false);
            toast.error("Reset password failed");
        })
    }

    useEffect(() => {
        setLoading(false);
        if(Auth.isLoggedIn())
            navigate("/cars")
        
    }, [])

    return (
        <> 
            <Helmet>
                <title>QR-Car | Password Reset</title>
            </Helmet>
            <h2 className='mb-3'>Reset your password</h2>
            <h5 className=''> Insert your email here. We will send you a link to reset your password. </h5>
            {/* <form className='form'> */}
            {/* EMAIL */}
            <label htmlFor='email' className='form-label'>Email</label>
            <input 
                type='email' 
                name='email' 
                id='email' 
                className='form-input' 
                placeholder='yourmail@mail.com'
                value={email}
                onChange={e => {
                    setEmail(e.target.value);
                    validator.current.showMessageFor('email');
                }} 
            />
            <div className='input-error-message'>
                {validator.current.message('email', email, 'required|email')}
            </div>
            
            {/* SUBMIT BUTTON */}
            <button type='button'  onClick={handleRegister} className='form-btn btn-mas-blue-fill' disabled={loading}>
                {loading ?
                <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                    <RingLoader color='#1F0807' loading={loading} size={30} />
                </div>: <>Submit request</>}
            </button>
            {/* </form> */}

        </>
    );
}
 
export default PasswordReset;