import Select from 'react-select';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react'
import SimpleReactValidator from 'simple-react-validator';
import { RingLoader } from 'react-spinners';
import PlatformService from '../../services/PlatformService';
import { select_style } from '../../layouts/SelectStyle';
import AuthService from '../../services/AuthService';
import "react-datepicker/dist/react-datepicker.css";
import { readUploadedFile} from '../../utils/getBase64';
import { toast } from 'react-toastify';
import ItemService from '../../services/ItemService';
import DocumentService from '../../services/DocumentService';
import { useNavigate } from 'react-router-dom';

const ModalAddDocument = (props) => {

    const [ documents, setDocuments ] = useState([]);
    const [ images, setImages ] = useState([]);
    const [ encodedImages, setEncodedImages ] = useState([]);
    const [ btnLoading, setBtnLoading ] = useState(false);
    const [ countries, setCountries ] = useState([]);
    const [ documentTypes, setDocumentTypes ] = useState([]);
    const [ profile, setProfile ] = useState({})

    const validator = useRef(new SimpleReactValidator({
        element: message => <div style={{color:'#ef5350'}}>{message}</div>
    }));

    const Platform = new PlatformService();
    const Document = new DocumentService();
    const Auth = new AuthService();

    useEffect(() => {
        let promises = [];
        promises.push(Platform.getLookup('countries'))
        promises.push(Platform.getLookup('documents'))
        promises.push(Auth.getProfile());
        Promise.all(promises).then((results) => {
            setCountries(results[0].data.data.map((e) => ({ value: e.id, label: e.name })));
            setDocumentTypes(results[1].data.data);
            setProfile(results[2].data.user);
        }).catch((err) => {
            console.log(err)
            setCountries([]);
            setDocumentTypes([]);
        })
    }, [])


    const handleDeleteDocument = (index) => {
        const docArray = [...documents];
        const filteredDocuments = docArray.filter((doc, docIndex) => docIndex !== index)
        console.log(filteredDocuments)
        setDocuments(filteredDocuments);
    }  

    const handleUploadDocument = (e) => {
        const uploadedDocument = Array.from(e.target.files);
        const documentsState = [...documents];
        if(props.platformConfig.max_allowed_images !== 0 && (documentsState.length + uploadedDocument.length) > props.platformConfig.max_allowed_images) {
            toast.error("You reached the maximum amount for documents.");
            return;
        }
        const preventDuplicateDocument = documentsState.filter(document => document.name === uploadedDocument && uploadedDocument[0].name);
        if(preventDuplicateDocument.length === 0) readUploadedFile(uploadedDocument[0]).then(res => setDocuments([...documentsState, {binary: res.binary, name: res.name, mimeType: res.mimeType, type:'', description:''}]));
    }

    const handleSubmit = () => {
        let promises = [];
        setBtnLoading(true);
        for(let i in documents) {
            promises.push(Document.store({
                content_type : "file",
                mime_type  : documents[i].mimeType,
                metadata: { 
                    name: documents[i].name,
                    description: documents[i].description,
                    type: documents[i].type,
                    owner: profile.wallet,
                },
                item_id: props.itemId,
                content: documents[i].binary
            }))
        }
        Promise.all(promises).then((res) => {
            props.closeModal();
            setBtnLoading(false);
            props.reload();
            toast.success("Document uploaded.");
        })
        .catch((err) => {
            setBtnLoading(false);
            toast.warning("There was an error while uploading the documents.");
        })
    }

    return (
        <>
            { props.modalOpened && <div className='customModal'> 
                <div className='h-100 vw-100 overflow-auto'>
                    <div className='modal-box'>
                        <div className="row pt-1 pb-3 mb-4 border-bottom-light-gray">
                            <div className="col-12 d-flex justify-content-between align-items-center">
                                <h4 className='py-2 my-auto dark-purple bold'>Add new document</h4>
                                <i className="fa fa-times fa-lg pointer p-2" aria-hidden="true" onClick={props.closeModal}></i>
                            </div>
                        </div>

                        <form>
                            <div className="row mb-4 border-bottom-light-gray">

                                {/* DOCUMENTS */}
                                <section className="row m-0">   
                                    <div className="col-12 p-0">
                                        <div className="row m-0">
                                            <label className="col-6 dark-purple bold fs-16 text-uppercase mb-2 p-0"> Documents {props.platformConfig.max_allowed_images > 0 && `(max. ${props.platformConfig.max_allowed_images})`}</label>
                                            {/* <label className={`col-6 dark-purple bold fs-16 text-uppercase mb-2 ${isEmpty(documents) ? 'd-none' : 'd-block'}`}> File Type </label>                                               */}
                                            {!isEmpty(documents) &&
                                                documents.map((document, index) => (
                                                    <section key={index} className='row m-0 p-0 py-2 mb-3'>
                                                        <div className='col-12 col-md-6 p-0 mb-2 pe-md-2'>
                                                            <div className=" custom-file-upload word-break bg-white">
                                                                <span className='w-75 h-100 pe-2 overflow-hidden d-flex align-items-center'>{document.name}</span>
                                                                <div 
                                                                    className='custom-btn btn-tomato d-center pointer' 
                                                                    style={{height:'36px'}}
                                                                    onClick={() => handleDeleteDocument(index)}    
                                                                >   Delete
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12 col-md-6 ps-md-2 p-0 mb-2'>
                                                            <Select 
                                                                id="file_type" 
                                                                name="file_type" 
                                                                options={documentTypes} 
                                                                placeholder="select the file type"
                                                                isClearable  
                                                                styles={select_style}
                                                                value={{value: document.type || '' , label: document.type || ''}}
                                                                // defaultValue={{value: document.type || ''}}

                                                                onChange={(e) => {
                                                                    const docs = [...documents];
                                                                    const selectedDoc = docs[index];
                                                                    selectedDoc.type =  e && e.value ? e.value : '';
                                                                    setDocuments(docs);
                                                                    validator.current.showMessageFor("file_type");
                                                                    
                                                                }}
                                                            />

                                                            <div className='input-error-message'>
                                                                {validator.current.message("file_type", document.type, "required")}
                                                            </div>

                                                        </div>
                                                        <div className='col-12 p-0 '>
                                                            <input 
                                                                type="text" 
                                                                name="description" 
                                                                id="description" 
                                                                className="form-input" 
                                                                placeholder=""
                                                                // onChange={e => document.description = e.target.value}
                                                                value={document.description}
                                                                onChange={e => {
                                                                    const docs = [...documents];
                                                                    const selectedDoc = docs[index];
                                                                    selectedDoc.description =  e.target.value ? e.target.value : '';
                                                                    setDocuments(docs);
                                                                }}
                                                            />
                                                        </div>
                                                    </section>
                                                ))
                                            }
                                            <div className="col-12 mb-2 p-0">
                                                <div className=" custom-file-upload">
                                                    <span>Please Choose the file</span>
                                                    <label htmlFor="documents" className='custom-btn btn-light-purple pointer d-center' style={{height:'36px'}}> Choose file </label>
                                                    <input 
                                                        type="file" 
                                                        name="documents" 
                                                        id="documents" 
                                                        className="form-input custom-file-upload" 
                                                        placeholder="No file selected"
                                                        onChange={e => handleUploadDocument(e)}
                                                    />
                                                </div>
                                                <div className='input-error-message'> </div>    
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                
                            </div>  
                            {/* <div className="row pt-1 ">
                                <div className="col-12 d-flex justify-content-end">
                                    <button className='custom-btn btn-milky-fill' type='submit' >Add Car</button>
                                </div>
                            </div> */}

                            <div className="row pt-1">
                                <div className="col-12 d-flex justify-content-end">
                                    <button className='custom-btn btn-coral' type='button' onClick={handleSubmit}>
                                        {btnLoading ? 
                                            <div className='w-100 h-100 d-center'> 
                                                <RingLoader color='#2E5077' loading={btnLoading} size={25}/> 
                                            </div>  
                                        : 'Add Document'}
                                    </button>
                                </div>
                            </div>


                        </form>   
                    </div>    
                </div>
            </div>}
        </>
    )
}

export default ModalAddDocument;