import axios from "axios";
import { domain } from "./env";
import { jwtDecode } from 'jwt-decode'

// const domain = "http://127.0.0.1:8000/"

export default class AuthService {

    constructor() {
        this.getToken = this.getToken.bind(this);
        this.isLoggedIn = this.isLoggedIn.bind(this);
        this.refreshAccessToken = this.refreshAccessToken.bind(this);
        this.isSuperUser = this.isSuperUser.bind(this);
        this.userId = this.userId.bind(this);
        this.getUserInfo = this.getUserInfo.bind(this);
        this.signup = this.signup.bind(this);
        this.logout = this.logout.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.updateProfile = this.updateProfile.bind(this);
        this.getDomain = this.getDomain.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
        this.resetChangePassword = this.resetChangePassword.bind(this);
    }

    getDomain = () => {
        return domain;
    }

    getToken = async(username, password) => {
        return axios.post(domain + "api/v1/accounts/token/", {
            username: username,
            password: password
        });
    }

    changePassword = async(data) => {
        return axios.post(domain + "api/v1/accounts/profile/change_password/", data);
    }

    getProfile = async() => {
        return axios.get(domain + "api/v1/accounts/profile/");
    }

    updateProfile = async(data) => {
        return axios.put(domain + "api/v1/accounts/profile/", data);
    }

    signup = async(data) => {
        return axios.post(domain + "api/v1/accounts/signup/", data);
    }

    confirmEmail = async(data) => {
        return axios.post(domain + "api/v1/accounts/email/verify/", data);
    }

    resetPassword = async(data) => {
        return axios.post(domain + "api/v1/accounts/password/reset/", data);
    }

    resetChangePassword = async(data) => {
        return axios.post(domain + "api/v1/accounts/password/change/", data);
    }

    refreshAccessToken  = async() => {
        const token = localStorage.getItem('refresh_token')
        return axios.post(domain + "api/v1/accounts/token/refresh/", {
            refresh: token
        });
    }

    isSuperUser = () => {
        const token = this.isLoggedIn();
        if(token) {
            const user = jwtDecode(token);
            return user.is_superuser;
        }
        return false
    }

    getUserInfo = () => {
        const token = this.isLoggedIn();
        if(token) {
            const user = jwtDecode(token);
            return user;
        }
        return false
    }

    userId = () => {
        const token = this.isLoggedIn();
        if(token) {
            const user = jwtDecode(token);
            return user.user_id;
        }
        return false
    }

    setToken(token) {
        localStorage.setItem('token', token);
    }

    setRefreshToken(token) {
        localStorage.setItem('refresh_token', token);
    }

    isLoggedIn() {
        const token = localStorage.getItem('token');
        return token;
    }
    
    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
    }
}