import React from 'react'

const Button = ({text, bg}) => {
    return (
        <>
            {bg==='milky-fill' && <button className={`custom-btn btn-${bg}`}> {text} </button>}
            {bg==='midnight-outline' && <button className={`custom-btn btn-${bg}`}> {text} </button>}
            {bg==='midnight-fill' && <button className={`custom-btn btn-${bg}`}> {text} </button>}

            {bg==='beige' && <button className={`custom-btn btn-${bg}`}> {text} </button>}  
            {bg==='light-pink' && <button className={`custom-btn btn-${bg}`}> {text} </button>}  
            {bg==='light-orange' && <button className={`custom-btn btn-${bg}`}> {text} </button>}  
            {bg==='coral' && <button className={`custom-btn btn-${bg}`}> {text} </button>}  
            {bg==='mas-blue-fill' && <button className={`custom-btn btn-${bg}`}> {text} </button>}  

            
        </>
    )
}

export default Button