import axios from "axios";
import { domain } from "./env";

export default class ItemService {

    constructor() {
        this.index = this.index.bind(this);
        this.get = this.get.bind(this);
        this.store = this.store.bind(this);
        this.update = this.update.bind(this);
        this.delete = this.delete.bind(this);
        this.getNFTs = this.getNFTs.bind(this);
        this.getPublic = this.getPublic.bind(this);
    }

    index = async(params) => {
        let x = new URLSearchParams(params);
        let keysForDel = [];
        x.forEach((value, key) => { // never reaches `c`
            if (value === '' || value === "null" || value === null || value === undefined)
                keysForDel.push(key);
        });
        keysForDel.forEach(key => {
            x.delete(key);
        });
        return axios.get(domain + `api/v1/items/?` + x.toString());
    }

    getNFTs = async(params) => {
        let x = new URLSearchParams(params);
        let keysForDel = [];
        x.forEach((value, key) => { // never reaches `c`
            if (value === '' || value === "null" || value === null || value === undefined)
                keysForDel.push(key);
        });
        keysForDel.forEach(key => {
            x.delete(key);
        });
        return axios.get(domain + `api/v1/items/nfts/?` + x.toString());
    }

    get = async(hash) => {
        return axios.get(domain + `api/v1/items/${hash}/`);
    }

    getPublic = async(hash) => {
        return axios.get(domain + `api/v1/public/item/${hash}/`);
    }

    store = async(data) => {
        return axios.post(domain + `api/v1/items/`, data);
    }

    update = async(hash, data) => {
        return axios.put(domain + `api/v1/items/${hash}/`, data);
    }

    delete = async(hash) => {
        return axios.delete(domain + `api/v1/items/${hash}/`);
    }

    getCertificate = async(hash) => {
        return axios.get(domain + `api/v1/blockchain/get_tx_pdf/${hash}/`);
    }
}