import React from 'react'
import { explorer_domain } from '../../services/env';

const ModalDocumentDetail = (props) => {

    return (
        <>
            { props.modalOpened && <div className='customModal'> 
            <div className='h-100 vw-100 overflow-auto'>
                <div className='modal-box'>
                    <div className="row pt-1 pb-3 mb-4 border-bottom-light-gray">
                        <div className="col-12 d-flex justify-content-between align-items-center">
                            <h4 className='py-2 my-auto dark-purple bold'>Document details</h4>
                            <i className="fa fa-times fa-lg pointer p-2" aria-hidden="true" onClick={props.closeModal}></i>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-12 py-2">
                            <span className='coral me-2 bold d-inline-block'>Name: </span>
                            <strong className='dark-purple word-break'>{props.document.metadata.name}</strong>
                        </div>
                        <div className="col-12 py-2">
                            <span className='coral me-2 bold d-inline-block'>Uploader: </span>
                            <strong className='dark-purple word-break'>{props.document.metadata.owner}</strong>
                        </div>
                        <div className="col-12 py-2">
                            <span className='coral me-2 bold d-inline-block'>Hash: </span>
                            <strong className='dark-purple word-break'>{props.document.hash}</strong>
                        </div>
                        <div className="col-12 py-2">
                            <span className='coral me-2 bold d-inline-block'>Transaction: </span>
                            <a href={`${explorer_domain}/tx/${props.document.tx_hash}/internal-transactions`} rel="noreferrer" target='_blank'>
                                <strong className='dark-purple word-break'>{props.document.tx_hash}</strong>
                            </a>
                        </div>
                        <div className="col-12 py-2">
                            <span className='coral me-2 bold d-inline-block'>Description: </span>
                            <strong className='dark-purple word-break'>{props.document.metadata.description}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>}
        </>
    )
}

export default ModalDocumentDetail;