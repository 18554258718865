const Tutorial = () => {
    return (
        <>
            <h4 className='bold mb-5'>Tutorial</h4> 
            <div className='grid-container'>
                
            </div>
        </>
    )
}

export default Tutorial;