import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import imgNotFound from '../../assets/images/notFound-404.png'
import Footer from '../../layouts/Footer';
import Header from '../../layouts/Header';
import Button from '../../layouts/Button';
import PaymentService from '../../services/PaymentService';
import { toast } from 'react-toastify';
import { RingLoader } from 'react-spinners';

const PaymentReturn = () => {

    const Payment = new PaymentService();
    const navigate = useNavigate();
    const [paymentStatus, setPaymentStatus] = useState("");
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        setLoading(true);
        const urlParams = new URLSearchParams(window.location.search);
        const status = urlParams.get('status');
        Payment.getPayment(urlParams.get('order_id')).then((res) => {
            setPaymentStatus(res.data.message.status);
            setTimeout(() => {
                navigate(`/cars/add-car/${res.data.message.gateway_order_id}`)
            }, 1000)
        })
        .catch((err) => {
            toast.error("Payment not found.")
        })
    },[]);

  return (
    <div className='main-layout'>
      <Helmet> <title>QR-Car | Payment { paymentStatus !== "" ? paymentStatus === "completed" ? "Successful" : "Failed" : ""}</title> </Helmet>
      <Header />
      <main className='mainLayout-main d-center'>
          <div className='max-container text-center p-2 p-md-5' >    
                {/* <img src={imgNotFound} alt="page not found" className='w-100' style={{maxWidth:'488px'}}/>   */}
                { paymentStatus !== "" ? paymentStatus === "completed" ? (
                    <>
                        <h2 className='mt-5 coral bold text-uppercase'>PAYMENT COMPLETED</h2>
                        <h3 className='mt-5 coral bold text-uppercase'>THANKS!</h3>    
                        <p className='dark-chocolate'>Please wait while redirecting you to creation page.</p>
                        <div className='w-100 h-100 d-center'> 
                            <RingLoader color='#2E5077' loading={true} size={25}/> 
                        </div> 
                    </>
                ) : (
                    <>
                        <h2 className='mt-5 coral bold text-uppercase'>PAYMENT FAILED</h2>
                        <p className='dark-chocolate'>Something went wrong while processing your payment.</p>
                        <Link to="/" className='d-inline-block round-50'> <Button text='Go To Homepage' bg='coral'/> </Link> 
                    </>
                ) : (
                    <div className='w-100 h-100 d-center'> 
                        <RingLoader color='#2E5077' loading={true} size={25}/> 
                    </div> 
                )}
          </div>
      </main>
      <Footer />
    </div> 
  )
}

export default PaymentReturn