import axios from "axios";
import AuthService from "./AuthService";
import { domain } from "./env";

// const domain = "http://127.0.0.1:8000/"
const Auth = new AuthService();

export default class PlatformService {

    constructor() {
        this.getRoles = this.getRoles.bind(this);
        this.getConfig = this.getConfig.bind(this);
        this.getLookup = this.getLookup.bind(this);
        this.getPackets = this.getPackets.bind(this);
    }

    getRoles = async() => {
        return axios.get(domain + "api/v1/platforms/roles/");
    }

    getConfig = async() => {
        return axios.get(domain + "api/v1/platforms/config/");
    }

    getLookup = async(table) => {
        return axios.get(domain + `api/v1/platforms/lookup/${table}/`);
    }

    getPackets = async() => {
        return axios.get(domain + `api/v1/platforms/packets/`);
    }
}