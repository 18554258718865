import Select from 'react-select';
import format from 'date-fns/format';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react'
import SimpleReactValidator from 'simple-react-validator';
import DatePicker from "react-datepicker";
import { RingLoader } from 'react-spinners';
import PlatformService from '../../services/PlatformService';
import { select_style } from '../../layouts/SelectStyle';
import AuthService from '../../services/AuthService';
import "react-datepicker/dist/react-datepicker.css";
import { readUploadedFile} from '../../utils/getBase64';
import { toast } from 'react-toastify';
import ItemService from '../../services/ItemService';
import DocumentService from '../../services/DocumentService';
import { useNavigate } from 'react-router-dom';
import PaymentService from '../../services/PaymentService';

const ModalAddCar = (props) => {

    const [ registrationId, setRegistrationId ] = useState("");
    const [ carName, setCarName ] = useState("");
    const [ country, setCountry ] = useState("");
    const [ date, setDate ] = useState("");
    const [ documents, setDocuments ] = useState([]);
    const [ images, setImages ] = useState([]);
    const [ encodedImages, setEncodedImages ] = useState([]);
    const [ btnLoading, setBtnLoading ] = useState(false);
    const [ countries, setCountries ] = useState([]);
    const [ documentTypes, setDocumentTypes ] = useState([]);
    const [ profile, setProfile ] = useState({})
    const [ payment, setPayment ] = useState(null);

    const validator = useRef(new SimpleReactValidator({
        element: message => <div style={{color:'#ef5350'}}>{message}</div>
    }));

    const Platform = new PlatformService();
    const Item = new ItemService();
    const Payment = new PaymentService();
    const Document = new DocumentService();
    const Auth = new AuthService();
    const navigate = useNavigate();

    useEffect(() => {
        let promises = [];
        promises.push(Platform.getLookup('countries'))
        promises.push(Platform.getLookup('documents'))
        promises.push(Auth.getProfile());
        if(props.paymentId) {
            promises.push(Payment.getPaymentByOrder(props.paymentId))
        }
        Promise.all(promises).then((results) => {
            setCountries(results[0].data.data.map((e) => ({ value: e.id, label: e.name })));
            setDocumentTypes(results[1].data.data);
            setProfile(results[2].data.user);
            if(results.length > 3)
                setPayment(results[3].data.payment)
        }).catch((err) => {
            console.log(err)
            setCountries([]);
            setDocumentTypes([]);
        })
    }, [])

    const handleDeleteImage = (index) => {
        const imgArray = [...images];
        const imgEncodedArray = [...encodedImages];

        const filteredImages = imgArray.filter((img, imgIndex) => imgIndex !== index);
        setImages(filteredImages);

        const filteredEncodedImages = imgEncodedArray.filter((img, imgIndex) => imgIndex !== index);
        setEncodedImages(filteredEncodedImages);
    }

    const handleUploadImage = (e) => {
        const uploadedImages = Array.from(e.target.files);
        const imagesState = [...images];
        const encodedImagesState = [...encodedImages];
        const newImages = [];
        const promises = [];
        if(props.platformConfig.max_allowed_images !== 0 && (imagesState.length + uploadedImages.length) > props.platformConfig.max_allowed_images) {
            toast.error("You reached the maximum amount for images.");
            return;
        }

        uploadedImages.map(uploadedImage => {
            const preventDuplicateImage = imagesState.filter(image => image.name === uploadedImage.name);
            if(preventDuplicateImage.length === 0) {
                promises.push(readUploadedFile(uploadedImage));
                newImages.push(uploadedImage);
            }
        })

        Promise.all(promises).then((results) => {
            // const res = [];
            // results.map(result => res.push(result.binary));
            // setEncodedImages([...encodedImagesState, ...res]);
            setEncodedImages([...encodedImagesState, ...results]);
        });   
        setImages([...imagesState, ...newImages]);
    }

    const handleDeleteDocument = (index) => {
        const docArray = [...documents];
        const filteredDocuments = docArray.filter((doc, docIndex) => docIndex !== index)
        setDocuments(filteredDocuments);
    }  

    const handleUploadDocument = (e) => {
        const uploadedDocument = Array.from(e.target.files);
        const documentsState = [...documents];
        let max_doc = props.platformConfig.max_allowed_images;
        if(payment) 
            max_doc = payment.packet_token;
        if(props.platformConfig.max_allowed_images !== 0 && (documentsState.length + uploadedDocument.length) > max_doc) {
            toast.error("You reached the maximum amount for documents.");
            return;
        }
        const preventDuplicateDocument = documentsState.filter(document => document.name === uploadedDocument && uploadedDocument[0].name);
        if(preventDuplicateDocument.length === 0) readUploadedFile(uploadedDocument[0]).then(res => setDocuments([...documentsState, {binary: res.binary, name: res.name, mimeType: res.mimeType, type:'', description:''}]));
    }

    const handleSubmit = () => {
        if(btnLoading)
            return;
        setBtnLoading(true);
        const data = {
            registration_id: registrationId,
            car_name: carName,
            country: country,
            owner: profile.wallet,
            date: date,
            images: encodedImages
        }
        if(props.platformConfig.payment_enabled) {
            data["payment_id"] = props.paymentId
        }
        Item.store({data}).then((res) => {
            let promises = [];
            for(let i in documents) {
                promises.push(Document.store({
                    content_type : "file",
                    mime_type  : documents[i].mimeType,
                    metadata: { 
                        name: documents[i].name,
                        description: documents[i].description,
                        type: documents[i].type,
                        owner: profile.wallet,
                    },
                    item_id: res.data.item.id,
                    content: documents[i].binary
                }))
            }
            Promise.all(promises).then((res) => {
                toast.success("Car saved.");
                setBtnLoading(false);
                navigate(`/car-details/${res.data.item.id}`);
            })
            .catch((err) => {
                toast.warning("Car saved but some error occurred while adding the documents. Please check the data.");
                setBtnLoading(false);
                navigate(`/car-details/${res.data.item.id}`)
            })
            
        })
        .catch((err) => {
            setBtnLoading(false);
            toast.error(err.response.data.message);
        })


    }


    return (
        <>
            { props.modalOpened && <div className='customModal'> 
                <div className='h-100 vw-100 overflow-auto'>
                    <div className='modal-box'>
                        <div className="row pt-1 pb-3 mb-4 border-bottom-light-gray">
                            <div className="col-12 d-flex justify-content-between align-items-center">
                                <h4 className='py-2 my-auto dark-purple bold'>Add new car</h4>
                                <i className="fa fa-times fa-lg pointer p-2" aria-hidden="true" onClick={props.closeModal}></i>
                            </div>
                        </div>

                        <form>
                            <div className="row mb-4 border-bottom-light-gray">

                                {/* PLATE ID */}
                                <div className="col-12 mb-2">
                                    <div className='d-flex flex-column-reverse flex-md-row'>
                                        <label htmlFor="registration_id" className="dark-purple bold fs-16 text-uppercase mb-2">
                                            <span>Plate ID</span> 
                                            <span className='required-color'> * </span>
                                        </label>
                                        {/* <div className='ms-md-auto text-center mb-2'>How to register your house ID based on the country you are in? 
                                            <a href='https://www.youtube.com/embed/IfTj8KJHxb8' target='_blank' className='milky text-decoration-none'> Click here!</a>
                                        </div> */}
                                    </div>
                                    <input 
                                        type="text" 
                                        name="registration_id" 
                                        id="registration_id" 
                                        className="form-input"
                                        placeholder="" 
                                        value={registrationId}
                                        onChange={e => {
                                            setRegistrationId(e.target.value);
                                            validator.current.showMessageFor("registration_id");
                                        }}
                                    />
                                    <div className='input-error-message'>
                                        {validator.current.message("registration_id", registrationId, "required")}
                                    </div>
                                </div>

                                {/* CAR NAME */}
                                <div className="col-12 col-md-6 mb-2">
                                    <label htmlFor="house_name" className="dark-purple bold fs-16 text-uppercase mb-2">
                                        Vehicle Name
                                        <span className='required-color'> * </span>
                                    </label>
                                    <input 
                                        type="text" 
                                        name="car_name" 
                                        id="car_name" 
                                        className="form-input" 
                                        placeholder="" 
                                        value={carName}
                                        onChange={e => {
                                            setCarName(e.target.value);
                                            validator.current.showMessageFor("car_name");
                                        }}
                                    />
                                    <div className='input-error-message'>
                                        {validator.current.message("car_name", carName, "required")}
                                    </div>
                                </div>

                                {/* COUNTRY */}
                                <div className="col-12 col-md-6 mb-2">
                                    <label htmlFor="country" className="dark-purple bold fs-16 text-uppercase mb-2">
                                        Country
                                        <span className='required-color'> * </span>
                                    </label>
                                    
                                    <Select 
                                        id="country" 
                                        name="country" 
                                        options={countries} 
                                        // placeholder="select the country"
                                        isClearable  
                                        styles={select_style}
                                        onChange={e => {
                                            if(e !== null) {
                                                setCountry(e);
                                            } 
                                            else {
                                                setCountry('');
                                            }


                                            // if(e.value === '') setRegion(''); 
                                            // if(e.value !== 'ITALY') setRegion('EE');
                                            validator.current.showMessageFor("country");
                                            // console.log(country)
                                        }}
                                        
                                    />
                                    <div className='input-error-message'>
                                        {validator.current.message("country", country, "required")}
                                    </div>
                                </div>
                
                                {/* OWNER ADDRESS */}
                                <div className="col-12 mb-2">
                                    <label htmlFor="owner_address" className="dark-purple bold fs-16 text-uppercase mb-2">
                                        Owner Address
                                        <span className='required-color'> * </span>
                                    </label>
                                    <input
                                        readOnly
                                        disabled
                                        type="text" 
                                        name="owner_address" 
                                        id="owner_address" 
                                        className="form-input" 
                                        value={profile.wallet}
                                    />
                                    <div className='input-error-message'></div>
                                </div>

                                {/* DATE TIME */}
                                <div className="col-12 mb-2">
                                    <label htmlFor="date" className="dark-purple bold fs-16 text-uppercase mb-2">
                                        Date time
                                        <span className='required-color'> * </span>
                                    </label>
                                    <DatePicker 
                                        name='date'
                                        id='date'
                                        // disabled
                                        className='form-input' 
                                        dateFormat="yyyy/MM/dd"
                                        selected={date} 
                                        // value={date}
                                        onChange={(date) => {
                                            // const selected_date = format(date, "yyyy/MM/dd")
                                            // setDate(selected_date);
                                            setDate(date);
                                            validator.current.showMessageFor("date");
                                        }} 
                                    />
                                    <div className='input-error-message'>
                                        {validator.current.message("date", date, "required")}
                                    </div>
                                </div>

                                {/* IMAGES */}
                                <section className="row m-0">   
                                    <div className="col-12 p-0">
                                        <div className="row m-0">
                                            <label className="col-6 dark-purple bold fs-16 text-uppercase mb-2 p-0"> Images {props.platformConfig.max_allowed_images > 0 && `(max. ${props.platformConfig.max_allowed_images})`}</label>
                                            <div className='col-12 p-0 d-flex flex-wrap'>                             
                                                {images && images.length > 0 &&
                                                    images.map((image, index) => (
                                                        <div key={index} className='m-2' >
                                                            <img className='b-shadow-mid-dark p-2 rounded' style={{width:'120px', height:'120px'}} src={image? URL.createObjectURL(image) : null} alt={image? image.name : null}/>
                                                            
                                                            <div  className='text-center my-2'>   
                                                                <i className="fa fa-times fa-lg p-2 pointer" aria-hidden="true" onClick={() => handleDeleteImage(index)}></i>
                                                            </div>
                                                        </div>         
                                                    ))
                                                }
                                            </div>
                                            <div className="col-12 mb-2 p-0">
                                                <div className="custom-file-upload">
                                                    <span>Please choose an image</span>
                                                    <label htmlFor="images" className='custom-btn btn-light-purple pointer d-center' style={{height:'36px'}}> Choose image </label>
                                                    <input 
                                                        type="file" 
                                                        multiple={true}
                                                        accept="image/*"
                                                        name="images" 
                                                        id="images"
                                                        value=''    // only this line solve my problem "Uploading File doesn't let me pick same file after I remove it from selection"
                                                        className="form-input custom-file-upload" 
                                                        placeholder="No file selected"
                                                        onChange={e => handleUploadImage(e)}
                                                    />
                                                </div>
                                                <div className='input-error-message'> </div>    
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                {/* DOCUMENTS */}
                                <section className="row m-0">   
                                    <div className="col-12 p-0">
                                        <div className="row m-0">
                                            <label className="col-6 dark-purple bold fs-16 text-uppercase mb-2 p-0"> Documents {payment ? `(max. ${payment.packet_token})` : props.platformConfig.max_allowed_images > 0 && `(max. ${props.platformConfig.max_allowed_images})`}</label>
                                            {/* <label className={`col-6 dark-purple bold fs-16 text-uppercase mb-2 ${isEmpty(documents) ? 'd-none' : 'd-block'}`}> File Type </label>                                               */}
                                            {!isEmpty(documents) &&
                                                documents.map((document, index) => (
                                                    <section key={index} className='row m-0 p-0 py-2 mb-3'>
                                                        <div className='col-12 col-md-6 p-0 mb-2 pe-md-2'>
                                                            <div className=" custom-file-upload word-break bg-white">
                                                                <span className='w-75 h-100 pe-2 overflow-hidden d-flex align-items-center'>{document.name}</span>
                                                                <div 
                                                                    className='custom-btn btn-tomato d-center pointer' 
                                                                    style={{height:'36px'}}
                                                                    onClick={() => handleDeleteDocument(index)}    
                                                                >   Delete
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12 col-md-6 ps-md-2 p-0 mb-2'>
                                                            <Select 
                                                                id="file_type" 
                                                                name="file_type" 
                                                                options={documentTypes} 
                                                                placeholder="select the file type"
                                                                isClearable  
                                                                styles={select_style}
                                                                value={{value: document.type || '' , label: document.type || ''}}
                                                                // defaultValue={{value: document.type || ''}}

                                                                onChange={(e) => {
                                                                    const docs = [...documents];
                                                                    const selectedDoc = docs[index];
                                                                    selectedDoc.type =  e && e.value ? e.value : '';
                                                                    setDocuments(docs);
                                                                    validator.current.showMessageFor("file_type");
                                                                    
                                                                }}
                                                            />

                                                            <div className='input-error-message'>
                                                                {validator.current.message("file_type", document.type, "required")}
                                                            </div>

                                                        </div>
                                                        <div className='col-12 p-0 '>
                                                            <input 
                                                                type="text" 
                                                                name="description" 
                                                                id="description" 
                                                                className="form-input" 
                                                                placeholder=""
                                                                // onChange={e => document.description = e.target.value}
                                                                value={document.description}
                                                                onChange={e => {
                                                                    const docs = [...documents];
                                                                    const selectedDoc = docs[index];
                                                                    selectedDoc.description =  e.target.value ? e.target.value : '';
                                                                    setDocuments(docs);
                                                                }}
                                                            />
                                                        </div>
                                                    </section>
                                                ))
                                            }
                                            <div className="col-12 mb-2 p-0">
                                                <div className=" custom-file-upload">
                                                    <span>Please Choose the file</span>
                                                    <label htmlFor="documents" className='custom-btn btn-light-purple pointer d-center' style={{height:'36px'}}> Choose file </label>
                                                    <input 
                                                        type="file" 
                                                        name="documents" 
                                                        id="documents" 
                                                        className="form-input custom-file-upload" 
                                                        placeholder="No file selected"
                                                        onChange={e => handleUploadDocument(e)}
                                                    />
                                                </div>
                                                <div className='input-error-message'> </div>    
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                
                            </div>  
                            {/* <div className="row pt-1 ">
                                <div className="col-12 d-flex justify-content-end">
                                    <button className='custom-btn btn-milky-fill' type='submit' >Add Car</button>
                                </div>
                            </div> */}

                            <div className="row pt-1">
                                <div className="col-12 d-flex justify-content-end">
                                    <button className='custom-btn btn-coral' type='button' onClick={handleSubmit}>
                                        {btnLoading ? 
                                            <div className='w-100 h-100 d-center'> 
                                                <RingLoader color='#2E5077' loading={btnLoading} size={25}/> 
                                            </div>  
                                        : 'Add Car'}
                                    </button>
                                </div>
                            </div>


                        </form>   
                    </div>    
                </div>
            </div>}
        </>
    )
}

export default ModalAddCar;