import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import Button from '../../layouts/Button';
import AuthService from '../../services/AuthService';

import imgregconfirm from '../../assets/images/reg-confirm@2x.png'
import { useEffect, useState } from 'react';
import PlatformService from '../../services/PlatformService';

const EmailConfirmation = () => {

    const Auth = new AuthService();
    const Platform = new PlatformService();
    const navigate = useNavigate();
    const [ emailVerification, setEmailVerification ] = useState(false);
    const [ searchParams, setSearchParams ] = useSearchParams();
    const [ success, setSuccess ] = useState(false);
    const [ error, setError ] = useState(false)

    useEffect(() => {
        console.log(searchParams)
        Auth.confirmEmail({
            email: searchParams.get("email"),
            code: searchParams.get("code")
        }).then((res) => {
            setSuccess(true);
        })
        .catch((err) => {
            setError(true);
        })
    }, [])

    return (
        <div className='h-100 d-center'>
            { success && <div className='w-100 mw-500 zIndex-1' style={{margin:'50px'}}>
                <img src={imgregconfirm} alt="confirmation" />
                <h2 className='fs-30 my-3'>Thanks!</h2>
                <p className='fs-18 mb-4'>Your email was successfully verified.</p>
                <div className="text-start">
                <Link to='/login' className='d-inline-block round-50'> <Button text='Login' bg='milky-fill' /> </Link>
                </div>
            </div> }
            { error && <div className='w-100 mw-500 zIndex-1' style={{margin:'50px'}}>
                <h2 className='fs-30 my-3'>We are sorry!</h2>
                <p className='fs-18 mb-4'>We are unable to verify your email.</p>
                <p className='fs-18 mb-4'>Please contact support.</p>
                <div className="text-start">
                <Link to='/login' className='d-inline-block round-50'> <Button text='Login' bg='milky-fill' /> </Link>
                </div>
            </div> }
        </div>
    )
}

export default EmailConfirmation;