import React from 'react';
import {Link} from 'react-router-dom';
import b4blogo from '../assets/images/b4b-logo.svg'

const Footer = () => {
    return (  
        <footer className="bg-liver white" >
            <section className="footer max-container p-0">
                <div className="row w-100 m-0">

                    <a href='https://www.board4birds.ch/' className="col-12 col-md-3 m-auto py-2 text-decoration-none d-flex flex-column align-items-center align-items-md-start">
                        <p className='mb-2 white' style={{fontSize:'10px'}}>Powered By</p>
                        {/* <img style={{width:'91px', height:'28px'}} src={process.env.PUBLIC_URL+"images/b4b-logo.svg"} alt="b4b-logo" /> */}
                        <img style={{width:'91px', height:'28px'}} src={b4blogo} alt="b4b-logo" />
                    </a>

                    <div className="col-12 col-md-9 m-auto py-2">               
                        <div className="d-flex flex-column flex-md-row justify-content-end text-center">
                            <Link to="/privacy-policy" className="form-link light-orange m-0 me-md-2 mb-2 mb-md-0">Privacy Policy</Link>
                            <span className='d-none d-md-inline'>|</span>
                            <span className='ms-md-2'>© Board 4 Birds - CHE 308.366.854 - All rights reserved</span>
                        </div>
                    </div>

                </div>
            </section>
        </footer>
    );
}
 
export default Footer;