import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Page404 from './pages/Generics/Page404';
import Home from './pages/Home/Home';
import FormLayout from './layouts/FormLayout';
import Login from './pages/Account/Login';
import Register from './pages/Account/Signup';
import RegisterConfirmation from './pages/Account/SignupConfirm';
import MainLayout from './layouts/MainLayout';
import List from './pages/Cars/List';
import Details from './pages/Cars/Details';
import Information from './pages/Cars/Information';
import Dashboard from './pages/Account/Dashboard';
import Profile from './pages/Account/Profile';
import Policy from './pages/Policy/Policy';
import Terms from './pages/Policy/Terms';
import Logout from './pages/Account/Logout';
import PaymentReturn from './pages/Payments/PaymentReturn';
import Invoices from './pages/Account/Invoices';
import Tutorial from './pages/Account/Tutorial';
import ChangePassword from './pages/Account/ChangePassword';
import EmailConfirmation from './pages/Account/EmailConfirm';
import PasswordReset from './pages/Account/PasswordReset';
import ResetConfirmation from './pages/Account/PasswordResetConfirm';
import PasswordConfirm from './pages/Account/PasswordConfirm';
import NFT from './pages/Account/NFT';
import PublicInformation from './pages/Cars/PublicInformation';


const Main = () => {
    

    return ( 
        <Routes>
            <Route element={<MainLayout/>}>

                <Route path='cars' element={<List />} />
                <Route path='cars/add-car/:id' element={<List/>} />
                <Route path='cars/payment' element={ <List/>} />
                <Route path='car-details/:id' element={ <Details /> } />
                <Route path='car-information/:id' element={<Information />} />
                <Route path='car-public-information/:tx_hash' element={<PublicInformation />} />
                <Route path="logout" element={<Logout/>} />
                <Route path="dashboard" element={<Dashboard/>}> 
                    <Route path="" element={<Profile/>} />
                    <Route path="user-profile" element={<Profile/>} />
                    <Route path="invoices" element={<Invoices />} />
                    <Route path="tutorial" element={<Tutorial />} />
                    <Route path="change-password" element={<ChangePassword />} />
                    <Route path="nfts" element={<NFT />} />
                </Route>
            </Route>
            <Route element={<FormLayout />}>
                <Route path='login' element={<Login/>} />
                <Route path='register' element={<Register />} />
                <Route path='register/confirm' element={<RegisterConfirmation />} />
                <Route path='email-confirm' element={<EmailConfirmation />} />
                <Route path='password-confirm' element={<PasswordConfirm />} />
                <Route path='password/reset' element={<PasswordReset />} />
                <Route path='password/confirm-sent' element={<ResetConfirmation />} />
            </Route>
            <Route path="payment/return" element={<PaymentReturn />} />
            <Route path="*" element={<Page404/>} />
            <Route path="privacy-policy" element={<Policy/>} />
            <Route path="terms-conditions" element={<Terms/>} />
            <Route path="/" exact element={<Home />} />
        </Routes>
    );
}
 
export default Main;