import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { RingLoader } from 'react-spinners';
import Button from '../../layouts/Button';
import PaymentService from '../../services/PaymentService';
import PlatformService from '../../services/PlatformService';

const Invoices = () => {
  const [paymentList, setPaymentList] = useState([]);
  const [ packetList, setPacketList ] = useState([]);
  const [loading, setLoading] = useState(false);
  const Payment = new PaymentService();
  const Platform = new PlatformService();

  useEffect(() => {
    setLoading(true);
    let promises = [];
    promises.push(Platform.getPackets());
    promises.push(Payment.getOwnPayments());
    Promise.all(promises).then(results => {
      setPacketList(results[0].data.packets);
      setPaymentList(results[1].data.payments);
      setLoading(false);

    });
  }, [])
  return (
    <>
      <h4 className='bold mb-5'>Invoices</h4> 
      <div className="">

        {loading ? 
          <div className='w-100 h-100 d-center py-3'> 
            <RingLoader color='#2E5077' loading={true} size={50}/> 
          </div>  
          :
          <table className='w-100'> 
            <thead>
            <tr className='text-center'>
              <th>Date</th>
              <th>Product Name</th>
              <th>Product Price</th>
              <th>Status</th>
              <th>Car</th>
              <th>Invoice</th>
            </tr>
            </thead>
            <tbody className='text-center'>
              {paymentList.map(paymentItem => (
                <tr key={paymentItem.id}>
                  <td>{format(new Date(paymentItem.created_at), 'dd/MM/yyyy')}</td>
                  <td>{ packetList.filter((e) => e.packet_code === paymentItem.packet_code)[0].name  }</td>
                  <td>{paymentItem.amount} €</td>
                  <td>{paymentItem.status}</td>
                  {paymentItem.status === 'completed' ? <td>
                    <Link to={paymentItem.item === null ? `/cars/add-car/${paymentItem.order_id}` : `/car-details/${paymentItem.item}`} >
                      <Button text={paymentItem.item === null ? 'Add Car' : 'Car'} bg={paymentItem.item ? 'mas-blue-fill' : 'coral'} />
                    </Link>
                  </td>
                  :
                    <td>
                      {/* <Link to='/cars/payment' >
                        <Button text='Payment' bg='midnight-fill'/>
                      </Link> */}
                    </td>
                  }
                  <td>
                    {paymentItem && paymentItem.invoice_url && <a href={paymentItem.invoice_url} className='d-block'><i className="fa fa-download fa-lg mas-blue"></i></a> }

                    
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        }
      </div>
    </>
  )
}

export default Invoices;