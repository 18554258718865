import { useLocation } from 'react-router';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet-async';

import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';
import privacyPolicy from '../../data/policy.json';
// import ScrollButton from '../../components/ui/ScrollButton';

const Policy = () => {

    const location = useLocation();
    const {policyLinks} = privacyPolicy;

    return (
        <div className='main-layout'>
            <Helmet> <title>QR-Car | Privacy Policy</title> </Helmet>
            <Header/>
            <main className='mainLayout-main'>
                <div className='max-container m-auto p-container'>    
                    <div className='row m-0 mb-4'>
                        <h4 className='col-12 py-2 my-auto dark-purple bold'>Privacy Policy</h4>
                        <h4 className='col-12 py-2 my-auto dark-purple fs-18'>(Updated to EU Regulation 2016/679 on the protection of personal data)</h4>
                    </div>

                    <div className='row mx-0'>
                        <div className='col-12 col-md-5 col-lg-4 col-xl-3 p-3'>
                            <ol className='py-3'>
                                {policyLinks.map(link => (
                                    <li key={link.id} className='policy-item capitalize-first'> 
                                        <HashLink key={link.id} to={`/privacy-policy/#${link.name}`} className={`text-decoration-none dark ${location.hash === `#${link.name}` ? 'active' : ''}`}>{link.title}</HashLink> 
                                    </li>
                                ))} 
                            </ol>
                        </div>

                        <div className='col-12 col-md-7 col-lg-8 col-xl-9 p-3'>
                            {/* INTRODUCTION */}
                            <div id='introduction' className='py-3 mb-3'>
                                <h4 className='dark-blue bold mb-4'> 1. Introduction </h4>
                                <p> Board 4 Birds (hereinafter referred to as "B4B"), in full respect of the privacy of its users, undertakes to process the personal data of users in accordance with the Federal Data Protection Act ("LPD") of 19 June 1992 and, where applicable, the Personal Data Protection Code and EU Regulation 2016/679 on the protection of individuals with regard to the processing of personal data and on the free circulation of such data (GDPR). </p>
                                <p> B4B uses <strong>cookies</strong> to make your browsing experience easier and more intuitive: cookies are small strings of text used to store certain information that may relate to the user, his/her preferences or the device used for the access to the internet (computer, tablet or mobile phone) and are used primarily to adapt the operation of the Website to the user’s expectations, offering a more customised browsing experience and storing your previous choices. This type of information does not include the user's personal data and, in accordance with the Swiss and European regulations applied, does not require the user's consent to the processing of data. For full details, we encourage users to refer to the Cookie Policy. </p>
                                <p> Conversely, in the sections of the Website where personal data is collected, i.e. any information that identifies or makes identifiable an individual (hereinafter also referred to as "data subject"), B4B publishes a specific statement pursuant to art. 14 LPD and, where applicable, art. 13/15 of the GDPR. Where required by the DPA and, where applicable, by the GDPR, the consent of the data subject will be required before processing his/her personal data. If the data subject provides personal data of third parties, he/she must ensure that the communication of the data to Board 4 Birds (and subsequent processing for the purposes specified in the privacy policy) complies with the DPA and, where applicable, with the GDPR. </p>
                                <p> This Privacy Policy provides information about how we manage and process the user’s personal information, defines the information that B4B collects and uses and the circumstances where personal information is disclosed to third parties. </p>
                                <p> B4B invites you to read it carefully. </p>
                                <p> This privacy policy and the Cookie Policy are only valid for the Website <strong><a href='https://www.qr-car.ch/'>https://www.qr-car.ch/</a></strong> and do not extend to other websites that may be accessed by the user through hypertext links. </p>
                                <p> The Privacy Policy and the Cookie Policy may change due to the introduction of new regulations. In this regard, we therefore invite the user to check this page from time to time. </p>
                                <p> If the user is under 16 years of age, the consent to the processing of personal data must be provided by the person who exercises parental authority. </p>
                            </div>

                            {/* DATA PROCESSING */}
                            <div id='data-processing' className='py-3 mb-3'>
                                <h4 className='dark-blue bold mb-4'> 2. Data processing </h4>

                                <h6 className='bold mt-4 mb-2'> 2.1. Data controller </h6>
                                <p className='mb-0 ps-3'> 1. The data controller is the natural or legal person, public authority, agency or other body that, individually or in conjunction with others, defines the purposes and the means of the processing of personal data. The data controller is also in charge of security profiles. </p>
                                <p className='mb-0 ps-3'> 2. With regard to the Website, the data controller is Board 4 Birds, registered with the Register of Commerce of the Canton of Ticino under the order number CHE- 308.366.854, Via Corti 5, 6828 Balerna, Switzerland. </p>

                                <h6 className='bold mt-4 mb-2'> 2.2. Place of data processing </h6>
                                <p className='ps-3'> 1. The data processing generated by the use of the Website is carried out at Board 4 Birds, Via Corti 5, 6828 Balerna, Switzerland. </p>
                                <p> Processing related to B4B' services may take place on the servers of third-party companies that provide hosting services on behalf of B4B, including cloud services. </p>
                                <p> Your data may also be processed by third party companies that B4B uses to perform activities on behalf of B4B (e.g. data analysis, archiving, user identification, newsletter service, etc.), in accordance with the applicable law. </p>

                                <h6 className='bold mt-4 mb-2'> 2.3. Responsible for data processing </h6>
                                <p> The responsible for data processing is the natural or legal person, public authority, agency or other body that processes personal data on behalf of the data controller. </p>
                                <p> Pursuant to Article 28 of EU Regulation no. 2016/679, upon appointment of the data controller, the data controller of the Website can be contactd at <strong className='bold'><a href="mailto:info@board4birds.ch">info@board4birds.ch</a></strong> . </p>  
                            </div>

                            {/* TYPE OF DATA PROCESSED */}
                            <div id='process-type' className='py-3 mb-3'>
                                <h4 className='dark-blue bold mb-4'> 3. Type of data processed </h4>
                                <p> The visit and consultation of the Website does not generally involve the collection and processing of personal data of the data subject except for navigation data and cookies as regulated in the Cookie Policy. </p>
                                <p> In addition to the so-called "navigation data", the personal data voluntarily provided by the data subject may be processed when he/she interacts with the functions of the Website or requests to use the services offered on the Website. In compliance with the LPD and the GDPR, Board 4 Birds may also collect the personal data of the data subject from third parties during the course of the activities. </p>
                                <p> The data provided by the user can be traced back to three different categories, as indicated below: </p>
                                <ul className='ms-2'>
                                    <li><p className='m-0'> Data provided on the contact form can be used for the newsletter service provided by MailChimp </p></li>
                                </ul>
                                <p> All the data provided by the user, as specified above, will be processed only with the explicit and specific consent obtained by the user, in accordance with Article 6 letter f) GDPR. The consent will be provided in a free, specific, informed and unambiguous manner. </p>
                                <p> By giving consent to the processing of data, the user acquires specific rights, pursuant to Articles 12-23, Chapter III of the GDPR, listed in Article 9. </p>
                            </div>

                            {/* METHODS AND PURPOSES OF PROCESSING */}
                            <div id='processing-purposes' className='py-3 mb-3'>
                                <h4 className='dark-blue bold mb-4'> 4. Methods and purposes of processing </h4>
                                <p> Board 4 Birds may process, including personal data worthy of particular protection, personal data of the data subject for the following purposes: the use of services and functions on the Website by the data subjects, management of requests and reports by the data subjects, sending newsletters, management of applications received through the Website, etc. </p>
                                <p> In addition, with the specific consent of the data subject, Board 4 Birds may process personal data for marketing purposes, i.e. to send the data subject promotional material and/or commercial communications relating to the services of Board 4 Birds, to the contact details supplied, either via traditional methods and / or means of contact such as paper mail, telephone calls with operator, etc., and via automated methods, such as communications via Internet, fax, e-mail, SMS, applications for mobile devices such as smartphones and tablets, cd. APPS, social network accounts (e.g. via Facebook, LinkedIn, Instagram or Twitter), automatically attended telephone calls, etc. </p>
                                <p> Personal data are processed either in paper and electronic form and entered into the organisation system in full compliance with the LPD and GDPR, including security and confidentiality profiles and inspired by the principles of correctness and lawfulness of processing. </p>
                                <p> The data is strictly kept for the period of time necessary to manage the purposes for which the data is collected in accordance with current regulations and legal obligations. In any case, rules a that prevent the storage and / or use of data for an indefinite period of time are applied and therefore storage time limited in accordance with the principle of minimization of data processing. </p>
                            </div>

                            {/* STORAGE AND COMMUNICATION OF PERSONAL DATA */}
                            <div id='storage-personal-data' className='py-3 mb-3'>
                                <h4 className='dark-blue bold mb-4'> 5. Storage and communication of personal data </h4>
                                <p> Personal data is stored and processed through computer systems owned by Board 4 Birds and managed by the same or by third party providers of technical services. </p>
                                <p>Specifically, all data provided by the user on the contact form can be used for the newsletter service provided by MailChimp</p>
                                <p> The personal data of the data subject may be communicated to: </p>
                                <ul className='ms-2'>
                                    <li><p className='m-0'> all persons to whom the right of access to such data is recognized by virtue of regulatory measures; </p></li>
                                    <li><p className='m-0'> to collaborators and/or employees of Board 4 Birds, within the scope of their duties; </p></li>
                                    <li><p className='m-0'> to all those natural and / or legal, public and / or private persons when the communication is necessary or functional to the performance of the activities of Board 4 Birds, in the manner and for the purposes shown above; </p></li>
                                </ul>
                                <p> The data is only processed by specifically authorized personnel, including personnel in charge of non-routine maintenance operations. </p>
                            </div>

                            {/* DATA TRANSFER */}
                            <div id='data-transfer' className='py-3 mb-3'>
                                <h4 className='dark-blue bold mb-4'> 6. Data transfer </h4>
                                <p> The data provided by the user may be transferred to third parties outside of the European Union, in accordance with the rules set by the GDPR and for purposes related to the implementation of the contract agreed by the parties. </p>
                            </div>

                            {/* SAFETY AND QUALITY */}
                            <div id='safety-and-quality' className='py-3 mb-3'>
                                <h4 className='dark-blue bold mb-4'> 7. Safety and quality </h4>
                                <p> By giving consent to the processing of data, the user authorizes such a transfer. The transfer of data to third parties removes B4B from any form of responsibility in relation to the processing of the data, however it is responsibility of the Foundation to try to ensure that third parties perform all the necessary actions to protect data. </p>
                                <p> Board 4 Birds is committed to protecting the security of the personal data of the data subject and complies with all the security provisions set in the applicable legislation in order to prevent data loss, unlawful or illicit use of data and unauthorised access to data, with particular reference to the minimum data protection requirements applicable. Board 4 Birds is committed to protecting the personal data managed against any unauthorized processing, through appropriate organizational and technical measures. In addition, the information systems and computer programs used by Board 4 Birds are configured in such a way as to minimize the use of personal and identification data; such data is processed only to achieve specific purposes pursued from time to time. Board 4 Birds uses a variety of advanced security technologies and procedures to help protect the personal data of the data subjects; for example, personal data is stored on secure servers located in places with secure and controlled access. The data subject can help Board 4 Birds their personal data to keep up to date by communicating any changes related to address, qualification, contact details, etc. </p>
                                <p> The technical features of B4B platform, such as data immutability, transparency and decentralised management, are elements that guarantee maximum data security, as well as being fully consistent with the scope and objectives pursued by the European regulations on cybersecurity (most recently Cybersecurity Act Reg. 2019/881 et seq. mm. and ii.). </p>
                            </div>

                            {/* NATURE OF PROVISION OF PERSONAL DATA */}
                            <div id='provision-of-personal-data' className='py-3 mb-3'>
                                <h4 className='dark-blue bold mb-4'> 8. Nature of provision of personal data </h4>
                                <p> The provision of certain personal data by the data subject is required to allow Board 4 Birds to manage communications such as requests made by the data subject or to respond to the data subject’s requests. This type of data is marked with an asterisk [*] and in this case the provision is required to allow the Board 4 Birds to process the request that, otherwise, cannot be processed. Otherwise, the collection of data that is not marked with an asterisk is optional: failure to provide it will not imply any consequences to the data subject. </p>
                                <p> The personal data provided by the data subject for marketing purposes, as specified in the section "Purposes and methods of processing" is optional and refusing to provide it will have no consequences. The consent given for marketing purposes is intended for the sending of communications made through methods and / or means of contact either automated and traditional, as defined above. </p>
                            </div>

                            {/* RIGHTS OF THE DATA SUBJECT */}
                            <div id='rights' className='py-3 mb-3'>
                                <h4 className='dark-blue bold mb-4'> 9. Rights of the data subject </h4>

                                <h6 className='bold mt-4 mb-2'> 9.1 Articles 15 (right of access) and 16 (right of rectification) of EU Regulation 2016/679. </h6>
                                <p> The data subject has the right to obtain confirmation from the holder of the data collected as to whether any personal data related to the data subject is being processed and, if so, to obtain access to the personal data and to the following information: </p>
                                <ol type='a' className='ms-2'>
                                    <li><p className='m-0'> the purposes of the processing; </p></li>
                                    <li><p className='m-0'> the categories of personal data concerned; </p></li>
                                    <li><p className='m-0'> the recipients or categories of recipients to whom the personal data have been or will be disclosed, in particular if the recipients are in third party countries or international organisations; </p></li>
                                    <li><p className='m-0'> the intended retention period of personal data or, if not possible, the criteria used to determine that period; </p></li>
                                    <li><p className='m-0'> the existence of the right of the data subject to request the holder of the data to correct or erase personal data or to restrict the processing of personal data concerning the data subject or to object the data processing; </p></li>
                                    <li><p className='m-0'> the right to lodge a complaint with a supervising authority;</p></li>
                                    <li><p className='m-0'> the existence of an automated decision-making process, including profiling and, at least in such cases, meaningful information on the logic used, and the importance and of such processing the expected consequences for the data subject. </p></li>
                                </ol>

                                <h6 className='bold mt-4 mb-2'> 9.2 Art. 17 (right to cancellation "right to be forgotten") of EU Reg. 2016/679. </h6>
                                <p> The data subject has the right to obtain the erasure of personal data relating to the data subject without undue delay from the holder of the data collected, and the holder of the data collected is required to erase the personal data without undue delay or make it permanently unusable and inaccessible, if one of the following reasons exists: </p>
                                <ol type='a' className='ms-2'>
                                    <li><p className='m-0'> personal data is no longer necessary for the purposes it was collected or otherwise processed; </p></li>
                                    <li><p className='m-0'> the data subject withdraws the consent on which the processing is based in accordance, where applicable, with Article 6(1)(a) of the GDPR or Article 9(2)(a) of the GDPR and if there is no other legal basis for the processing; </p></li>
                                    <li><p className='m-0'> the data subject objects to the processing of his or her personal data and there are no overriding legitimate grounds for processing, or objects to the processing of his or her personal data for direct marketing purposes, including profiling in the measure it relates to such direct marketing; </p></li>
                                    <li><p className='m-0'> personal data has been processed unlawfully; </p></li>
                                    <li><p className='m-0'> personal data must be deleted in order to comply with a legal obligation under the DPA; </p></li>
                                    <li><p className='m-0'> personal data has been collected in connection with the provision of informative services, where applicable, as referred to in Article 8(1) GDPR. </p></li>
                                </ol>
                                <p> Revocation of consent to the processing of personal data may not result in the erasure of certain information provided by the user, as the Blockchain platform involves the recording of certain data related to transactions permanently across all nodes of the platform. </p>
                                
                                <h6 className='bold mt-4 mb-2'> 9.3 Art. 18 (right to limit treatment) of EU Reg. 2016/679. </h6>
                                <p> The data subject has the right to obtain the restriction of the processing from the holder of the data collection when one of the following conditions applies: </p>
                                <ol type='a' className='ms-2'>
                                    <li><p className='m-0'> the data subject contests the accuracy of the personal data for as long as it is necessary for the holder of the data collection to verify the accuracy of such personal data; </p></li>
                                    <li><p className='m-0'> the processing is unlawful and the data subject objects to the erasure of the personal data and requests instead the use to be restricted; </p></li>
                                    <li><p className='m-0'> although the holder of the data collected no longer needs it for the purposes of processing, personal data is necessary for the data subject to establish, exercise or defend a right in court; </p></li>
                                    <li><p className='m-0'> the data subject has objected to the processing pursuant to Article 21(1) of the GDPR, where applicable, pending verification as to whether the legitimate reasons of the holder of the data collected take precedence over the reasons of the data subject. </p></li>
                                </ol>

                                <h6 className='bold mt-4 mb-2'> 9.4 Article 20 (right to data portability) of EU Regulation 2016/679. </h6>
                                <p> The data subject has the right to receive in a structured, commonly used and machine-readable format the personal data relating to him/her and provided to the holder of the data collected and has the right to transmit such data to another holder of a data collection without hindrance from the previous holder of the data collected. </p>
                            </div>

                            {/* REVOCATION OF CONSENT TO THE PROCESSING OF DATA AND CONTACTS */}
                            <div id='revocation' className='py-3 mb-3'>
                                <h4 className='dark-blue bold mb-4'> 10. Revocation of consent to the processing of data and contacts </h4>
                                <p> The data subject has the right to withdraw his or her consent to the processing of his or her personal data. In this regard, the person concerned may send an e-mail request to the following e-mail address: <strong className='bold'><a href='mailto:info@board4birds.ch'>info@board4birds.ch</a></strong> , or may send a registered mail to the following address: Board 4 Birds, Via Corti 5, 6828 Balerna, Switzerland, accompanied by a photocopy of his identity document, with the following text: {'<>'}. </p>
                                <p> Upon completion of this operation, the personal data of the data subject shall be removed or otherwise made unusable from the archives as soon as possible, except as specified in Article 9.2, last section. </p>
                                <p> If the data subject wishes to have more information about the processing of his/her personal data, or to exercise the rights listed in this Privacy Policy, he/she may send an email request to the following e-mail address: <strong className='bold'><a href='mailto:info@board4birds.ch'>info@board4birds.ch</a></strong> , or a registered mail to the following address: Board 4 Birds, Via Corti 5, 6828 Balerna, Switzerland. </p>
                                <p> Before providing or changing any information, it may be required to verify the identity of the data subject and answer some questions. A response will be provided as soon as possible. </p>
                                <p> If the data subject considers the response provided by Board 4 Birds regarding the processing of personal data unsatisfactory, he or she may contact the Federal Data Protection and Information Commissioner (FDPIC). </p>
                                <p> Board 4 Birds reserves the right to update or modify this Privacy Policy from time to time if there are any changes in the way Board 4 Birds processes your data or if there are any regulatory changes. The Privacy Policy, updated from time to time, will be published on the Website. The person concerned is therefore invited to check the Website for any changes and/or updates to this Privacy Policy. </p>
                            </div>
                        </div>
                    </div>            
                </div>
            </main>
            <Footer/>
            {/* <ScrollButton route='/privacy-policy'/>    */}
        </div>  
    )
}

export default Policy;