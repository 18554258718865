import axios from "axios";
import AuthService from "./AuthService";
import { domain } from "./env";

// const domain = "http://127.0.0.1:8000/"
const Auth = new AuthService();

export default class PaymentService {

    constructor() {
        this.createPayment = this.createPayment.bind(this);
        this.getPayment = this.getPayment.bind(this);
        this.getOwnPayments = this.getOwnPayments.bind(this);
        this.getPaymentByOrder = this.getPaymentByOrder.bind(this);
    }

    createPayment = async(data) => {
        return axios.post(domain + "api/v1/payments/create/", data);
    }

    getPayment = async(id) => {
        return axios.get(domain + `api/v1/payments/get/${id}/`);
    }

    getPaymentByOrder = async(id) => {
        return axios.get(domain + `api/v1/payments/by_order/${id}/`);
    }

    getOwnPayments = async(id) => {
        return axios.get(domain + `api/v1/payments/own/`);
    }
    
}