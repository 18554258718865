import { Link } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import moment from 'moment';
import { tableHeader } from '../../data/constant';
import imgdefaultcar from '../../assets/images/car.png'

const CarsList = ({carsList , cars}) => {
    return (  
        <section className='row m-0'>
            <div className='col-12 px-1 overflow-hidden'>
                <div className='t-head mb-2 py-1'>   
                    <div className='px-2'>
                        {cars.length > 0 ? 
                            <ul className='house-header mb-0'>
                                {tableHeader.map( (header, index) => {
                                    const item = index === 0 ? 
                                    <li key={index} className='house-header-content dark-purple px-1' style={{maxWidth:header.width, width:header.width}}>
                                        {header.name}
                                    </li> :
                                    <li key={index} className='house-header-content dark-purple px-3' style={{width:header.width}}>
                                        {header.name}
                                    </li>
                                    return item;
                                })}
                            </ul>
                        : 
                            <Skeleton variant='rectangle' height={44} className='rounded m-0'/>
                        }
                    </div>
                    <div className='house-list-wrapper p-2'>
                        {cars.length > 0 ?
                            cars.map(car => (
                                <Link key={car.id} to={`/car-details/${car.id}`} className='house-list-item pointer text-decoration-none'>
                                    <li className='house-content justify-content-start' style={{width:"67px", maxWidth:"67px"}}>
                                        { car.data.images && car.data.images.length > 0 ? (
                                            <img style={{maxWidth:'67px'}} className='w-100 h-100' src={car.data.images[0].binary} alt="" />
                                        ):(
                                            <img style={{maxWidth:'67px'}} className='w-100 h-100' src={imgdefaultcar} alt="" />
                                        ) }
                                        
                                    </li>
                                    <li className='house-content px-3' style={{width:"180px"}}> {car.data.registration_id} </li>
                                    <li className='house-content px-3' style={{width:"180px"}}> {car.data.car_name} </li>
                                    <li className='house-content px-3' style={{width:"200px"}}> {moment(car.data.date).format('YYYY-MM-DD')} </li>                                        
                                    <li className='house-content px-3' style={{width:"120px"}}> {car.data.country.label} </li>        
                                    <li className='house-content px-3 word-break' style={{width:"320px"}}> {car.data.owner} </li>
                                </Link>
                            ))
                            : 
                            <>
                                <Skeleton variant='rectangle' height={67} className='rounded' sx={{ margin:'10px 0 20px' }} />
                                <Skeleton variant='rectangle' height={67} className='rounded' sx={{ margin:'10px 0 20px' }} />
                                <Skeleton variant='rectangle' height={67} className='rounded' sx={{ margin:'10px 0 20px' }} />
                                <Skeleton variant='rectangle' height={67} className='rounded' sx={{ margin:'10px 0 20px' }} />
                                <Skeleton variant='rectangle' height={67} className='rounded' sx={{ margin:'10px 0 20px' }} />
                                <Skeleton variant='rectangle' height={67} className='rounded' sx={{ margin:'10px 0 20px' }} />
                                <Skeleton variant='rectangle' height={67} className='rounded' sx={{ margin:'10px 0 20px' }} />
                                <Skeleton variant='rectangle' height={67} className='rounded' sx={{ margin:'10px 0 20px' }} />
                                <Skeleton variant='rectangle' height={67} className='rounded' sx={{ margin:'10px 0 20px' }} />
                                <Skeleton variant='rectangle' height={67} className='rounded' sx={{ margin:'10px 0 20px' }} />  
                            </>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default CarsList;