import axios from "axios";
import { domain } from "./env";
// const domain = "http://127.0.0.1:8000/"

export default class DocumentService {

    constructor() {
        this.index = this.index.bind(this);
        this.get = this.get.bind(this);
        this.store = this.store.bind(this);
        this.update = this.update.bind(this);
        this.delete = this.delete.bind(this);
        this.getByItem = this.getByItem.bind(this);
        this.getPublicByItem = this.getPublicByItem.bind(this);
    }

    index = async(page, limit, search) => {
        return axios.get(domain + `api/v1/documents/?page=${page}&limit=${limit}&search=${search}`);
    }

    get = async(hash) => {
        return axios.get(domain + `api/v1/documents/${hash}/`);
    }

    getByItem = async(hash) => {
        return axios.get(domain + `api/v1/documents/by_item/${hash}/`);
    }

    getPublicByItem = async(hash) => {
        return axios.get(domain + `api/v1/public/documents/by_item/${hash}/`);
    }

    store = async(data) => {
        return axios.post(domain + `api/v1/documents/`, data);
    }

    update = async(hash, data) => {
        return axios.put(domain + `api/v1/documents/${hash}/`, data);
    }

    delete = async(hash) => {
        return axios.delete(domain + `api/v1/documents/${hash}/`);
    }
}