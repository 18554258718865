import React, { useEffect, useState } from 'react'
import { EmailShareButton, TelegramShareButton, WhatsappShareButton } from "react-share";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import whatsappImg from '../../assets/images/icons/media/whatsapp@3x.png'
import telegramImg from '../../assets/images/icons/media/telegram@3x.png'
import mailImg from '../../assets/images/icons/media/mail@3x.png'
import linkImg from '../../assets/images/icons/media/link@3x.png'


const ModalDocumentShare = (props) => {

    const [ isCopyLink, setCopyLink ] = useState(false);

    useEffect(() => {
        setCopyLink(false);
    }, [props.modalOpened])


    return (
        <>
            { props.modalOpened && <div className='customModal'> 
            <div className='h-100 vw-100 overflow-auto'>
                <div className='modal-box'>
                    <div className="row pt-1 pb-3 mb-4 border-bottom-light-gray">
                        <div className="col-12 d-flex justify-content-between align-items-center">
                            <h4 className='py-2 my-auto dark-purple bold'>Share Document</h4>
                            <i className="fa fa-times fa-lg pointer p-2" aria-hidden="true" onClick={props.closeModal}></i>
                        </div>
                    </div>
                    <p className="dark-purple bold fs-18 mb-4">You can share the document through the following media</p>
                    <div className='row m-0'>

                        {/* WHATSAPP */}
                        <div className="col-6 col-md-3 d-flex justify-content-start justify-content-md-center align-items-center my-3" > 
                            <WhatsappShareButton url={props.shareDoc}> 
                                <img className='pointer p-2' src={whatsappImg} alt="whatsapp"/> 
                                <span className='dark-purple bold'>Whatsapp</span>
                            </WhatsappShareButton>
                        </div>

                        {/* TELEGRAM */}
                        <div className="col-6 col-md-3 d-flex justify-content-start justify-content-md-center align-items-center my-3">  
                            <TelegramShareButton url={props.shareDoc}> 
                                <img className='pointer p-2' src={telegramImg} alt="telegram" /> 
                                <span className='dark-purple bold'>Telegram</span>
                            </TelegramShareButton>
                        </div>

                        {/* MAIL */}
                        <div className="col-6 col-md-3 d-flex justify-content-start justify-content-md-center align-items-center my-3"> 
                            <EmailShareButton url={props.shareDoc}>
                                <img className='pointer p-2' src={mailImg} alt="mail" />
                                <span className='dark-purple bold'>Mail</span>
                            </EmailShareButton>
                        </div>

                        {/* COPY LINK */}
                        <div className="col-6 col-md-3 d-flex flex-wrap justify-content-start justify-content-md-center align-items-center my-3 dark-purple" > 
                            <CopyToClipboard text={props.shareDoc} onCopy={() => setCopyLink(true)}>
                                <img className='pointer p-2' src={linkImg} alt="link"  />
                            </CopyToClipboard>
                            <span className={`bold ${isCopyLink ? 'text-success' : ''}`}>{`${isCopyLink ? 'Link Copied!' : 'Copy Link'}`}</span>
                        </div>  
                    </div>
                </div>
            </div>
        </div>}
        </>
    )
}

export default ModalDocumentShare;