import React, { useEffect, useState } from 'react'
import { RingLoader } from 'react-spinners';
import PlatformService from '../../services/PlatformService';
import PaymentService from '../../services/PaymentService';
import { toast } from 'react-toastify';

const ModalPackets = (props) => {

    const Platform = new PlatformService();
    const Payment = new PaymentService();
    const [ packets, setPackets ] = useState([]);
    const [ selectedPayment, setSelectedPayment ] = useState(null);
    const [ btnLoading, setBtnLoading ] = useState(false);

    useEffect(() => {
        Platform.getPackets().then((res) => {
            setPackets(res.data.packets)
        })
        .catch((err) => {
            setPackets([])
        })
    },[]);

    const submitPayment = () => {
        if(!selectedPayment) {
            toast.error("You have to select a packet first.")
            return
        }
        const baseUrl = window.location
        const data = {
            "packet_code": selectedPayment,
            "redirect_url": baseUrl.protocol + "//" + baseUrl.hostname + "/payment/return"
        }
        Payment.createPayment(data).then((res) => {
            if(res.status === 201 && res.data.status === "ok")
                window.location.replace(res.data.payment.url);
            else
                toast.error("Unable to process payment.")
        })
        .catch((err) => {
            toast.error("Unable to process payment.")
        })
    }

    return (
        <>
            { props.modalOpened && <div className='customModal'> 
            <div className='h-100 vw-100 overflow-auto'>      
                <div className='modal-box'>
                    <div className="row pt-1 pb-3 m-0 mb-4 border-bottom-light-gray">
                        <div className="col-12 p-0 d-flex justify-content-between align-items-center">
                            <h4 className='py-2 my-auto dark-purple bold'>Payment</h4>
                            <i className="fa fa-times fa-lg pointer p-2 pe-0" aria-hidden="true" onClick={props.closeModal}></i>
                        </div>
                    </div>
                    <div className='row m-0 mb-3'>
                        <div className="col-12 my-auto text-center">
                            <h2 className='coral bold mb-3 fs-30'>Our Plans</h2>
                            <p className='mb-3 fs-18 dark-purple'>Choose the Plan that best suits your needs:</p>
                        </div>
                    </div>
                    
                    <div className='row m-0 mb-3'>
                        <div className="col-12 my-auto text-center d-flex justify-content-center" style={{gap:'20px'}}>
                            { packets.map((item, index) => (
                                <button key={item.packet_code} className={`custom-btn ${selectedPayment === item.packet_code ? 'btn-mas-blue' : 'btn-mas-blue-outline'}`} onClick={() => setSelectedPayment(item.packet_code)}>{item.name}</button>
                            ))}                     
                        </div>
                    </div>
                    <form >
                        <div className="row m-0 mb-4 border-bottom-light-gray">
                            <div className="text-center py-2">
                                <section className=''>
                                    
                                    <div className='row m-0 text-center d-flex flex-column align-items-center'>
                                        {packets.map((item, index) => (
                                            selectedPayment === item.packet_code && <div key={item.packet_code} className="col-12 col-sm-9 col-lg-9 px-0 py-4 p-sm-4 p-md-3 d-center" >
                                                <div className='p-4 border-mas-blue round-20 w-90 h-100 d-flex flex-column'>
                                                    <h2 className='mas-blue bold mb-3 text-center fs-40'>{item.name}</h2>
                                                    <a href="mailto: info@board4birds.ch" className='mb-5 text-decoration-none'>
                                                        <h4 className='coral bold m-0 text-center fs-40'>{item.amount} €</h4>
                                                    </a>
                                                    <p>
                                                        {item.description}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </section>
                            </div>
                        </div>  
                        <div className="row pt-1">
                            <div className="col-12 d-flex justify-content-end">
                                <button className='custom-btn btn-coral' type='button' onClick={submitPayment}>
                                    {btnLoading ? 
                                        <div className='w-100 h-100 d-center'> 
                                            <RingLoader color='#2E5077' loading={btnLoading} size={25}/> 
                                        </div>  
                                    : 'Payment'}
                                </button>
                            </div>
                        </div>
                    </form>   
                </div>
            </div>
        </div>}
        </>
    )
}

export default ModalPackets;