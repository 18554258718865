import plantIcon from '../assets/images/icons/accordion/plant_icon.png'
import actIcon from '../assets/images/icons/accordion/act_icon.png'
import modifyIcon from '../assets/images/icons/accordion/modify_icon.png'
import mortgagesIcon from '../assets/images/icons/accordion/mortgages_icon.png'
import landRegistryIcon from '../assets/images/icons/accordion/land_registry_icon.png'
import otherActIcon from '../assets/images/icons/accordion/other_actS_icon.png'

export const tableHeader = [
    {
        name: 'IMG',
        width: '67px'
    },
    {
        name: 'PLATE ID',
        width: '180px'
    },
    {
        name: 'VEHICLE NAME',
        width: '180px'
    },
    {
        name: 'REGISTRATION DATE',
        width: '200px'
    },
    {
        name: 'COUNTRY',
        width: '120px'
    },
    // {
    //     name: 'REGION',
    //     width: '100px'
    // },
    // {
    //     name: 'ZIP',
    //     width: '80px'
    // },
    // {
    //     name: 'ADDRESS',
    //     width: '170px'
    // },
    {
        name: 'OWNER',
        width: '320px'
    },
]
export const houseDetailHeader = [
    {
        name: 'IMG',
        width: '67px'
    },
    {
        name: 'NAME',
        width: '180px'
    },
    {
        name: 'DESCRIPTION',
        width: '180px'
    },
    {
        name: 'TYPE',
        width: '180px'
    },
    {
        name: 'DOWNLOAD',
        width: '180px'
    },
    {
        name: 'DETAILS',
        width: '90px'
    },
    {
        name: 'SHARE',
        width: '90px'
    },
]
export const accordionList = [
    {
        id: 1,
        label: 'Carta di circolazione',
        label_en: 'Car registration certificate',
        imgURL: plantIcon,
        checked: false,
        sub: []
    },
    {
        id: 2,
        label: 'Certificato di Proprietà',
        label_en: 'Car ownership certificate',
        imgURL: actIcon,
        checked: false,
        sub: []
    },
    {
        id: 3,
        // label: 'Atto di vendita (qualora non sia già compilato sul CDP)',
        label: 'Atto di vendita',
        label_en: 'Car sales certificate',
        imgURL: mortgagesIcon,
        checked: false,
        sub: []
    },
    {
        id: 4,
        label: 'Libretto dei tagliandi',
        label_en: 'Car service booklet',
        imgURL: modifyIcon,
        checked: false,
        sub: []
    },
    {
        id: 5,
        label: 'Interventi di verniciatura',
        label_en: 'Car painting interventions',
        imgURL: landRegistryIcon,
        checked: false,
        sub: []
    },
    {
        id: 6,
        label: 'Certificato di Rilevanza Storico Collezionistica',
        label_en: 'Certificate of historical car collective relevance',
        imgURL: otherActIcon,
        checked: false,
        sub: []
    }, 
    {
        id: 7,
        label: 'Attestato di Datazione e Storicità',
        label_en: 'Certificate of car dating and historicity',
        imgURL: otherActIcon,
        checked: false,
        sub: []
    }, 
    {
        id: 8,
        label: 'Bolli',
        label_en: 'Car tax',
        imgURL: otherActIcon,
        checked: false,
        sub: []
    }, 
    {
        id: 9,
        label: 'Parti non originali o rettificate',
        label_en: 'Non-original or reworked spare part',
        imgURL: otherActIcon,
        checked: false,
        sub: []
    }, 
]