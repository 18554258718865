import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import imgNotFound from '../../assets/images/notFound-404.png'
import Footer from '../../layouts/Footer';
import Header from '../../layouts/Header';
import Button from '../../layouts/Button';

const Page404 = () => {
  return (
    <div className='main-layout'>
      <Helmet> <title>QR-Car | Page not found</title> </Helmet>
      <Header />
      <main className='mainLayout-main d-center'>
          <div className='max-container text-center p-2 p-md-5' >    
                <img src={imgNotFound} alt="page not found" className='w-100' style={{maxWidth:'488px'}}/>  
                <h3 className='mt-5 coral bold text-uppercase'>SOMETHING’S MISSING</h3>    
                <p className='dark-chocolate'>Page not found</p>
                <Link to="/" className='d-inline-block round-50'> <Button text='Go To Homepage' bg='coral'/> </Link>  
          </div>
      </main>
      <Footer />
    </div> 
  )
}

export default Page404