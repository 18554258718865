import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import Button from '../../layouts/Button';
import AuthService from '../../services/AuthService';

import imgregconfirm from '../../assets/images/reg-confirm@2x.png'
import { useEffect, useState } from 'react';
import PlatformService from '../../services/PlatformService';
import { RingLoader } from 'react-spinners';
import { useRef } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';

const PasswordConfirm = () => {

    const Auth = new AuthService();
    const Platform = new PlatformService();
    const navigate = useNavigate();
    const [ password, setPassword ] = useState("");
    const [ confirmPassword, setConfirmPassword ] = useState("")
    const [ loading, setLoading ] = useState(false);
    const validator = useRef(new SimpleReactValidator({
        element: message => <div style={{color:'#1F0807'}}>{message}</div>
    }));;

    const [ searchParams, setSearchParams ] = useSearchParams();
    const [ isVisible, setVisible ] = useState(false);
    const [ isVisibleConfirm, setVisibleConfirm ] = useState(false);

    useEffect(() => {
        console.log(searchParams)
        
    }, [])

    const handleRegister = () => {
        Auth.resetChangePassword({
            email: searchParams.get("email"),
            code: searchParams.get("code"),
            "password": password,
            "password_confirmation": confirmPassword
        }).then((res) => {
            toast.success("Password changed successfully.");
            navigate("/login")
        })
        .catch((err) => {
            toast.error("Something went wrong.")
        })
    }

    return (
        <div className='h-100 d-center'>
            <div className='w-100 mw-500 zIndex-1'>
                <h2 className='fs-30 my-3'>Password reset</h2>
                <h5 className=''> Please set your new password. </h5>
                {/* <form className='form'> */}
                {/* PASSWORD */}
                <label htmlFor='password' className='form-label'>Password</label>
                <div className='position-relative'>
                    <input 
                        type={!isVisible ? 'password' : 'text'} 
                        name='password' 
                        id='password' 
                        className='form-input' 
                        placeholder='* * * * * * * * * * *'
                        value={password}
                        onChange={e => {
                            setPassword(e.target.value);
                            validator.current.showMessageFor('password');
                        }}
                    />
                    <span className='show-password' onClick={() => setVisible(!isVisible)}>
                        {!isVisible ? <i className='fa fa-eye dark' aria-hidden='true'></i> : <i className='fa fa-eye-slash dark' aria-hidden='true'></i> }
                    </span>
                </div>
                <div className='input-error-message'>
                    {validator.current.message('password', password, 'required|min:3')}
                </div>

                {/* CONFIRM PASSWORD */} 
                <label htmlFor='confirm' className='form-label'>Confirm Password</label>
                <div className='position-relative'>
                    <input 
                        type={!isVisibleConfirm ? 'password' : 'text'} 
                        name='confirm password' 
                        id='confirm' 
                        className='form-input' 
                        placeholder='* * * * * * * * * * *'
                        value={confirmPassword}
                        onChange={e => {
                            setConfirmPassword(e.target.value);
                            validator.current.showMessageFor('confirm');
                        }}
                    />
                    <span className='show-password' onClick={() => setVisibleConfirm(!isVisibleConfirm)}>
                        {!isVisibleConfirm ? <i className='fa fa-eye dark' aria-hidden='true'></i> : <i className='fa fa-eye-slash dark' aria-hidden='true'></i> }
                    </span>
                </div>
                <div className='input-error-message'>
                    {validator.current.message('confirm password', confirmPassword, 'required')}
                    {password === confirmPassword ? null :
                        <div style={{color:'#1F0807'}}>Confirm password is not matched</div>
                    }
                </div>
                
                {/* SUBMIT BUTTON */}
                <button type='button'  onClick={handleRegister} className='form-btn btn-mas-blue-fill' disabled={loading}>
                    {loading ?
                    <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                        <RingLoader color='#1F0807' loading={loading} size={30} />
                    </div>: <>Submit request</>}
                </button>
            </div> 
        </div>
    )
}

export default PasswordConfirm;