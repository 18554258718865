import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import RingLoader from "react-spinners/RingLoader";
import { select_style_profile } from '../../layouts/SelectStyle';
import Select from 'react-select';
import AuthService from '../../services/AuthService';
import { toast } from 'react-toastify';
import PlatformService from '../../services/PlatformService';

const Profile = () => {

    const Auth = new AuthService();
    const Platform = new PlatformService();

    const [loading, setLoading] = useState(false);
    const [isModify, setModify] = useState(false);

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [city, setCity] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [country, setCountry] = useState({});
    const [address, setAddress] = useState("");
    const [company, setCompany] = useState("");
    const [vat, setVat] = useState("");
    const [, forceUpdate] = useState();
    const [ userInfo, setUserInfo ] = useState({});
    const [countriesName, setCountriesName] = useState([])

    const validator = useRef(new SimpleReactValidator({
        messages: {},
        element: message => <div style={{color:'#ef5350'}}>{message}</div>
    }));

    const handleProfile = async event => {
        event.preventDefault();
        setLoading(true);
        const user_profile = { 
            first_name: firstname,
            last_name: lastname,
            email: userInfo.email, 
            attributes: {
                country_value: country.value, 
                country_label: country.label ,
                city: city,
                zip_code: zipCode,
                address: address, 
                company_name: company, 
                vat_number: vat
            }
        };
        Auth.updateProfile(user_profile).then((res) => {
            toast.success("Profile updated.");
            setModify(false);
            setLoading(false);
        })
        .catch((err) => {
            toast.error("There was an error while updating your profile.");
            setLoading(false);
        })

    }

    useEffect(() => {
        Platform.getLookup('countries').then((countries) => {
            setCountriesName(countries.data.data.map((e) => ({ value: e.id, label: e.name })))
        })
        .catch((err) => {
            setCountriesName([])
        })
        Auth.getProfile().then((res) => {
            setFirstname(res.data.user.first_name);
            setLastname(res.data.user.last_name);
            setUserInfo(res.data.user)
            if(res.data.user.attributes) {
                if(res.data.user.attributes.vat_code)
                    setVat(res.data.user.attributes.vat_code)
                if(res.data.user.attributes.city)
                    setCity(res.data.user.attributes.city)
                if(res.data.user.attributes.address)
                    setAddress(res.data.user.attributes.address)
                if(res.data.user.attributes.zip_code)
                    setZipCode(res.data.user.attributes.zip_code)
                if(res.data.user.attributes.company_name)
                    setCompany(res.data.user.attributes.company_name)
                if(res.data.user.attributes.country_value && res.data.user.attributes.country_label)
                    setCountry({ value: res.data.user.attributes.country_value, label: res.data.user.attributes.country_label })
            }
        })
        .catch((err) => {
            toast.error("Unable to fetch your profile data.")
        })
    }, []);

    const handleModify = () => {
        setModify(true);
        // setFirstname(userInfo.person_name);
        // setLastname(userInfo.person_surname);
        // setCity(userInfo.city);
        // setZipCode(userInfo.zip_code);
        // setCountry({value: userInfo.country , label: userInfo.country_name});
        // setAddress(userInfo.address);
        // setCompany(userInfo.company_name);
        // setVat(userInfo.vat_number);
    }

    const handleCancel = () => {
        setModify(false);
        // setFirstname(userInfo.person_name);
        // setLastname(userInfo.person_surname);
        // setCity(userInfo.city);
        // setZipCode(userInfo.zip_code);
        // setCountry({value: userInfo.country , label: userInfo.country_name});
        // setAddress(userInfo.address);
        // setCompany(userInfo.company_name);
        // setVat(userInfo.vat_number);
    }

  return (
    <>
        <h4 className='bold mb-5'>User profile</h4> 
        {loading ?
            <div className="">
                <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                    <RingLoader color='#312782' loading={loading} size={80} />
                </div>
            </div>
            :
            <>
                <form onSubmit={handleProfile}>

                    <div className="d-flex flex-wrap"> 
                        {/* FIRST NAME */}
                        <div className="mb-4 me-sm-5 w-100 w-max-350">
                            <label htmlFor="name" className="d-block mb-2"> Name <span className='required-color'> * </span> </label>
                            <div className="position-relative">
                                <input 
                                    type='search'
                                    disabled={!isModify} 
                                    className={`form-input ${!isModify && 'bg-light-skyblue'}`}
                                    id='name' 
                                    name='name'
                                    value={firstname}
                                    onChange={e => {
                                        setFirstname(e.target.value);
                                        validator.current.showMessageFor("name");
                                    }}
                                />
                                <div className='input-error-message'>
                                    {isModify && validator.current.message("name", firstname, "required")}
                                </div>
                            </div>
                        </div> 

                        {/* LAST NAME */}
                        <div className="mb-4 me-sm-5 w-100 w-max-350">
                            <label htmlFor="surname" className='d-block mb-2'> Surname <span className='required-color'> * </span> </label>
                            <div className="position-relative">
                                <input 
                                    type='search'
                                    disabled={!isModify} 
                                    className={`form-input ${!isModify && 'bg-light-skyblue'}`}
                                    id='surname' 
                                    name='surname'
                                    value={lastname}
                                    onChange={e => {
                                        setLastname(e.target.value);
                                        validator.current.showMessageFor("surname");
                                    }}
                                />
                                <div className='input-error-message'>
                                    {isModify && validator.current.message("surname", lastname, "required")}
                                </div>
                            </div>
                        </div> 

                        {/* EMAIL */}
                        <div className="mb-4 me-sm-5 w-100 w-max-350">
                            <label htmlFor="email" className='d-block mb-2'> Email <span className='required-color'> * </span> </label>
                            <input 
                                disabled={true}
                                className='form-input bg-light-skyblue'
                                value={userInfo.email}
                            />
                            <div className='input-error-message'> </div>
                        </div>

                        {/* COUNTRY */}
                        <div className="mb-4 me-sm-5 w-100 w-max-350">
                            <label htmlFor="country" className='d-block mb-2'> Country <span className='required-color'> * </span> </label>
                            <div className="position-relative">
                                {isModify ?
                                    <>
                                        <Select 
                                            id="country" 
                                            name="country" 
                                            options={countriesName} 
                                            isClearable  
                                            isDisabled={!isModify}
                                            styles={select_style_profile}
                                            defaultValue={{value: country.value, label: country.label}}
                                            onChange={e => {
                                                // console.log(e && e.value);
                                                setCountry(e ? {value: e.value, label: e.label} : '');
                                                validator.current.showMessageFor("country");
                                            }}  
                                        />
                                        <div className='input-error-message'>
                                            {isModify && validator.current.message("country", country, "required")}
                                        </div> 
                                    </>
                                    :
                                    <input
                                        className='form-input bg-light-skyblue'
                                        disabled={true} 
                                        value={country.label}
                                    />  
                                } 
                            </div>
                        </div> 

                        {/* CITY */}
                        <div className="mb-4 me-sm-5 w-100 w-max-350">
                            <label htmlFor="city" className='d-block mb-2'> City <span className='required-color'> * </span> </label>
                            <div className="position-relative">
                                <input 
                                    type='search'
                                    disabled={!isModify} 
                                    className={`form-input ${!isModify && 'bg-light-skyblue'}`}
                                    id='city' 
                                    name='city'
                                    value={city}
                                    onChange={e => {
                                        setCity(e.target.value);
                                        validator.current.showMessageFor("city");
                                    }}
                                />
                                <div className='input-error-message'>
                                    {isModify && validator.current.message("city", city, "required")}
                                </div>
                            </div>
                        </div> 

                        {/* ZIP CODE */}
                        <div className="mb-4 me-sm-5 w-100 w-max-350">
                            <label htmlFor="zipCode" className='d-block mb-2'> Zip Code <span className='required-color'> * </span> </label>
                            <div className="position-relative">
                                <input 
                                    disabled={!isModify} 
                                    type='search'
                                    className={`form-input ${!isModify && 'bg-light-skyblue'}`}
                                    id='zipCode' 
                                    name='zipCode'
                                    value={zipCode}
                                    onChange={e => {
                                        setZipCode(e.target.value);
                                        validator.current.showMessageFor("zip code");
                                    }}
                                />
                                <div className='input-error-message'>
                                    {isModify && validator.current.message("zip code", zipCode, "required|numeric|min:5|max:5")}
                                </div>
                            </div>
                        </div> 

                        {/* ADDRESS */}
                        <div className="mb-4 me-sm-5 w-100 w-max-350">
                            <label htmlFor="address" className='d-block mb-2'> Address <span className='required-color'> * </span> </label>
                            <div className="position-relative">
                                <input 
                                    type='search'
                                    disabled={!isModify} 
                                    className={`form-input ${!isModify && 'bg-light-skyblue'}`}
                                    id='address' 
                                    name='address'
                                    value={address}
                                    onChange={e => {
                                        setAddress(e.target.value);
                                        validator.current.showMessageFor("address");
                                    }}
                                />
                                <div className='input-error-message'>
                                    {isModify && validator.current.message("address", address, "required")}
                                </div>
                            </div>
                        </div> 

                        {/* COMPANY NAME */}
                        <div className="mb-4 me-sm-5 w-100 w-max-350">
                            <label htmlFor="company" className='d-block mb-2'>Company Name</label>
                            <div className="position-relative">
                                <input 
                                    type='search'
                                    disabled={!isModify}
                                    className={`form-input ${!isModify && 'bg-light-skyblue'}`}
                                    id='company' 
                                    name='company'
                                    value={company}
                                    onChange={e => {
                                        setCompany(e.target.value);
                                        if(e.target.value === '') setVat('');
                                    }}
                                />
                            </div>
                        </div>

                        {/* VAT NUMBER */}
                        <div className="mb-4 me-sm-5 w-100 w-max-350">
                            <label htmlFor="vat" className='d-block mb-2'>VAT Number</label>
                            <input 
                                type='search'
                                disabled={!isModify || company === ''}
                                className={`form-input ${(!isModify || company === '') && 'bg-light-skyblue'}`}
                                id='vat' 
                                name='vat'
                                value={vat}
                                onChange={e => {
                                    setVat(e.target.value);
                                    validator.current.showMessageFor("vat");
                                }}
                            />
                            <div className='input-error-message'>
                                {isModify && company === "" && validator.current.message("vat", vat, "numeric|min:11|max:11") }
                                {isModify && company !== "" && validator.current.message("vat", vat, "required|numeric|min:11|max:11")  }   
                            </div>
                        </div>  
                    </div>

                    {/* BUTTONS */}
                    <div>
                        {isModify &&
                        <>
                            <button className='custom-btn btn-mas-blue-fill me-3 mt-3' type='submit'>Save</button>
                            <span className='custom-btn me-3 mt-3 required-color pointer' onClick={handleCancel} >Cancel</span>
                        </>}
                    </div>

                </form>

                <div> { !isModify && <button className='custom-btn btn-mas-blue-fill me-3 mt-3' onClick={handleModify}>Modify</button> } </div> 
            </>
        }
    </>
  )
}

export default Profile