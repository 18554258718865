import React, { useEffect, useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import qrcarlogowhite from '../assets/images/qrCar-logo-white@2x.png'
import avatar from '../assets/images/avatar.png'
import AuthService from '../services/AuthService';

const Header = () => {

    const [ user, setUser ] = useState();
    const Auth = new AuthService();
    const navigate = useNavigate();

    useEffect(() => {
        if (window.location.pathname.includes('car-public-information')) {
            setUser(null)
        } else {
            Auth.getProfile().then((res) => {
                setUser(res.data.user)
            })
            .catch((err) => {
                Auth.logout();
                navigate("/login")
            })
        }
    }, [])

    return (  
        <header className="bg-coral white" >
            <section className="header max-container p-0">
                <div className="row w-100 m-0">
                    <div className="col-6 d-flex align-items-center">
                        <Link to={!user ? '/' : '/cars'} className='d-inline-flex'>
                            <img src={qrcarlogowhite} alt="QR-Car logo" style={{height:'68px'}} className='w-100 w-logo-max'/>
                        </Link>
                    </div>
                    {user ? <div className="col-6 d-flex flex-column flex-md-row justify-content-end align-items-center m-auto py-1">
                        <div className="avatar-wrapper me-md-2 mb-1 mb-md-0 align-self-end">
                            <img className="w-100 h-100" src={avatar} alt="QR_Avatar" />
                        </div>
                        <div className='align-self-end'>
                            <span className="d-none d-md-block">{user.first_name} {user.last_name}</span>
                            {/* <Link to="/logout" className="form-link italic me-3">Logout</Link> */}
                            <Link to="/dashboard" className="form-link italic dark-chocolate">Settings</Link>
                        </div>
                    </div> : null}
                </div>
            </section>
        </header>
    );
}
 
export default Header;