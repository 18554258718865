import { BrowserRouter as Router } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ToastContainer, Slide } from 'react-toastify';
import Main from './Main';
import axios from 'axios'
import AuthService from './services/AuthService';

const Auth = new AuthService();

axios.interceptors.request.use(
  async config => {
    const token = Auth.isLoggedIn();
    if (token)
      config.headers['Authorization'] = `Bearer ${token}`
    //config.headers['Accept'] = `application/json`
    return config;
  },
  error => {
    Promise.reject(error)
});

axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, async function (error) {
  const originalRequest = error.config;
  if(error.code === "ERR_NETWORK")
    return Promise.reject(error);
  if(!error.response) {
    Auth.logout();
    window.location.pathname = "/login";
  }
  if(error.response.request.status === 401) {
    const refreshtoken = localStorage.getItem('refresh_token')
    if (!refreshtoken)
      window.location.pathname = "/login";
    if(!originalRequest._retry && !originalRequest.url.includes("refresh")) {
      originalRequest._retry = true;
      const resp = await Auth.refreshAccessToken();  
      const token = resp.data.access;
      Auth.setToken(token);
      return axios(originalRequest);
    } else {
      window.location.pathname = "/login";
    }
  }
  return Promise.reject(error);
});

const App = () => {
  return (
    <HelmetProvider>
      <Helmet> <title>QR-Car</title> </Helmet>
      <Router>
        <Main/>
        <ToastContainer transition={Slide} />
      </Router>
    </HelmetProvider>
  );
}
 
export default App;
