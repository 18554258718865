import { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import imglogo from '../assets/images/qrCar-logo-coral@2x.png'
import imglanding from '../assets/images/landing_car@2x.png'

const FormLayout = () => {

    const navigate = useNavigate();
    const {pathname} = useLocation();
    
    return (  
        <div className="row m-0 vh-100">
            <div className="col-12 col-md-7 col-xl-8">
                <div className="login-side-wrapper">
                    <h3 className="side-title py-3">
                        <Link to="/"> <img src={imglogo} alt="QR-Car logo" style={{height:'69px'}} /> </Link>
                        {/* <Link to="/" className='text-decoration-none'> <h2 className='coral bold'>QR-Car</h2> </Link> */}
                    </h3>
                    <h2 className="side-subtitle">All in one place, protected by Blockchain</h2>
                    <p className="side-paragraph">Group all the documents that are part of your vehicle, 
                        from the financial documents of the loan to the documents of the Specialized specifications. 
                        All under the security of the blockchain.
                    </p>
                    <div className="w-100 text-center">
                        <img className='w-100' src={imglanding} alt="QR-Car" />
                    </div>
                </div>
            </div>
            <div className="aside-formLayout col-12 col-md-5 col-xl-4 p-0 bg-coral white font-lato">
                <div className='position-absolute h-100 w-100 overflow-hidden'>
                    <div className='poligono pol-1'></div>
                    <div className='poligono pol-2'></div>
                </div>

                <div className='row position-relative zIndex-1' style={{margin: '90px 0 0'}}>   
                    <div className='col-11 col-sm-10 col-md-11 col-lg-10 col-xl-9 m-auto py-5'>
                        {pathname === '/password-confirm' || pathname === '/register/confirm' || pathname === '/email-confirm' || pathname === '/password-reset' ? null :
                        <>
                            <h2 className='mb-3'>Welcome!</h2>
                            <div className='d-flex sel-btn position-relative round-50 overflow-hidden'>
                                <span className={`d-center w-50 h-100 transition fs-18 bold pointer ${pathname !== '/login' && 'dark-chocolate'}`} onClick={() => navigate('/login')}>Sign in</span>
                                <span className={`d-center w-50 h-100 transition fs-18 bold pointer ${pathname !== '/register' && 'dark-chocolate'}`} onClick={() => navigate('/register')}>Sign up</span>
                                <span className={`position-absolute d-center w-50 h-100 bg-mas-blue transition fs-18 bold white round-50 ${pathname === '/register' && 'translateX-100'}`}> {`${pathname === '/register' ? 'Sign up' : 'Sign in'}`} </span>
                            </div>
                            <h5 className=''> {` Insert your credentials to ${pathname === '/register' ? 'create' : 'access'} your account`} </h5>
                        </>}
                        <Outlet/>
                    </div>
                </div>  
            </div>
        </div>
    );
}
 
export default FormLayout;