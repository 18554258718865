import { Link, useNavigate } from 'react-router-dom';

import Button from '../../layouts/Button';
import AuthService from '../../services/AuthService';

import imgregconfirm from '../../assets/images/reg-confirm@2x.png'
import { useEffect, useState } from 'react';
import PlatformService from '../../services/PlatformService';

const RegisterConfirmation = () => {

    const Auth = new AuthService();
    const Platform = new PlatformService();
    const navigate = useNavigate();
    const [ emailVerification, setEmailVerification ] = useState(false);

    // useEffect(() => {
    //     if(Auth.isLoggedIn()) 
    //         navigate("/cars")
    //     Platform.getConfig().then((res) => {
    //         setEmailVerification(res.data.config.email_confirmation);
    //     })
    //     .catch((err) => {

    //     })
    // }, [])

    return (
        <div className='h-100 d-center'>
            <div className='w-100 mw-500 zIndex-1' style={{margin:'50px'}}>
                <img src={imgregconfirm} alt="confirmation" />
                <h2 className='fs-30 my-3'>Welcome on board!</h2>
                <p className='fs-18 mb-4'>The registration was successful.</p>
                <p className='fs-18 mb-4'>Please check your inbox to complete and confirm your site registration</p>
                <p className='fs-18 mb-4'>You didn't receive the email? <Link to='/email-verification' className='d-inline-block round-50'>Click here to resend.</Link></p>
                <div className="text-start">
                <Link to='/login' className='d-inline-block round-50'> <Button text='Login' bg='milky-fill' /> </Link>
                </div>
            </div>
        </div>
    )
}

export default RegisterConfirmation;