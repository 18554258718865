import { Outlet, useLocation } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import AuthService from '../../services/AuthService';
import { useEffect } from 'react';

const Dashboard = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const Auth = new AuthService();
    useEffect(() => {
        if(!Auth.isLoggedIn())
            navigate("/login")
    },[])

    return (
        <main>
            <Helmet>
                <title>QR-Car | Dashboard</title>
            </Helmet>
            <section className='max-container m-auto p-container overflow-auto'>
                <div className='row mb-4 m-0'>
                    <h4 className='col-12 col-sm-6 py-2 dark-purple bold'>Settings</h4> 
                </div>
                <div className='row mb-4 m-0'>
                    <div className='col-12 col-sm-5 col-md-4 col-xl-3 dark-purple bold' style={{ borderRight:'2px solid #E3E3E3'}}>
                        <ul className='list-unstyled me-sm-3'>
                            <Link to="/dashboard/user-profile" className='d-flex mb-3 dark-purple text-decoration-none'>
                                <li className={`w-100 rounded p-4 pointer ${(location.pathname === '/dashboard' || location.pathname === '/dashboard/' || location.pathname === '/dashboard/user-profile' || location.pathname === '/dashboard/user-profile/') && 'bg-light-skyblue'}`}> User profile </li>
                            </Link>

                            <Link to="/dashboard/invoices" className='d-flex mb-3 dark-purple text-decoration-none'>
                                <li className={`w-100 rounded p-4 pointer ${(location.pathname === '/dashboard/invoices' || location.pathname === '/dashboard/invoices/') && 'bg-light-skyblue'}`}> Invoices </li>
                            </Link>

                            <Link to="/dashboard/nfts" className='d-flex mb-3 dark-purple text-decoration-none'>
                                <li className={`w-100 rounded p-4 pointer ${(location.pathname === '/dashboard/nfts' || location.pathname === '/dashboard/nfts/') && 'bg-light-skyblue'}`}> My NFTs </li>
                            </Link>

                            <Link to="/dashboard/tutorial" className='d-flex mb-3 dark-purple text-decoration-none'>
                                <li className={`w-100 rounded p-4 pointer ${(location.pathname === '/dashboard/tutorial' || location.pathname === '/dashboard/tutorial/') && 'bg-light-skyblue'}`}> Tutorial </li>
                            </Link>
                            
                            <Link to="/dashboard/change-password" className='d-flex mb-3 dark-purple text-decoration-none'>
                                <li className={`w-100 rounded p-4 pointer ${(location.pathname === '/dashboard/change-password' || location.pathname === '/dashboard/change-password/') && 'bg-light-skyblue'}`}> Change password </li>
                            </Link>

                            <Link to="/logout" className='d-inline-flex mb-3 dark-purple text-decoration-none required-color'>
                                <li className={`rounded p-4 pointer`}> Logout </li>
                            </Link>    
                        </ul>
                    </div>
                    <div className='col-12 col-sm-7 col-md-8 col-xl-9 dark-purple bold' >
                        <div className='ms-sm-3'>
                            <Outlet/>
                        </div>
                    </div> 
                </div>
            </section>
        </main>
    )
}

export default Dashboard;