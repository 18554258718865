import { useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { useTranslation, Trans } from 'react-i18next';

// import Navbar from '../../components/ui/Navbar';
import homePage from '../../data/homePage.json';
// import ScrollButton from '../../components/ui/ScrollButton';

import landingcar from '../../assets/images/landing_car@2x.png'
import virtualimg from '../../assets/images/Virtual.png'
import imglogowhite from '../../assets/images/qrCar-logo-white@2x.png'
import imghanden from '../../assets/images/hand_en.png'
import imgrequadrobit from '../../assets/images/logo-requadrobit@2x.png'
import imgre2retina from '../../assets/images/Logo_Re2_retina@2x.png'
import imgcaricon from '../../assets/images/icons/car-icon.png'
import imgpublicicon from '../../assets/images/icons/public-icon.png'
import imgsearchicon from '../../assets/images/icons/search-icon.png'
import imgjusticicon from '../../assets/images/icons/justic-icon.png'
import imggdpricon from '../../assets/images/icons/gdpr-icon.png'
import imgsecurityicon from '../../assets/images/icons/security-icon.png'
import imgstep1 from '../../assets/images/step-1@2x.png'
import imgstep2 from '../../assets/images/step-2@2x.png'
import imgstep3 from '../../assets/images/step-3@2x.png'
import imgstep4 from '../../assets/images/step-4@2x.png'
import imgavatar from '../../assets/images/avatar@2x.png'
import Navbar from '../../layouts/Navbar';

const dynamicImages = {
    "imghanden" : imghanden,
    "imgrequadrobit": imgrequadrobit,
    "imgre2retina": imgre2retina,
    "imgcaricon": imgcaricon,
    "imgpublicicon": imgpublicicon,
    "imgsearchicon": imgsearchicon,
    "imgjusticicon": imgjusticicon,
    "imggdpricon": imggdpricon,
    "imgsecurityicon": imgsecurityicon,
    "imgstep1": imgstep1,
    "imgstep2": imgstep2,
    "imgstep3": imgstep3,
    "imgstep4": imgstep4,
    "imgavatar": imgavatar
}

const Home = () => {

    const { scopri, caratteristiche, applicazioni, cosa_serve, come_funziona, offerta, personalizzazione, orizzonti, faq, testimonial, contatti } = homePage;
    
    const { t, i18n } = useTranslation();
    const [ user, setUser ] = useState(false)

    const [isOpen, setOpen] = useState(['d_one']);
    const [isShadow, setShadow] = useState(false);
    
    const handleNavbarShadow = () =>  window.scrollY > 0 ? setShadow(true) : setShadow(false);
    window.addEventListener('scroll', handleNavbarShadow);

    const handleCollapse = id => {
        const openArray = [...isOpen];
        if(isOpen.includes(id)) {
            const filteredCollapse = openArray.filter(item => item !== id);
            setOpen(filteredCollapse);
        }
        else {
            setOpen([...isOpen, id]);
        }
    }

    return (
        <main>
            <Navbar user={user} shadow={isShadow}/>

            <section className='bg-coral white py-5'>
                <div className='max-container m-auto d-flex flex-column overflow-hidden px-sm-4'> 
                    <div className='d-none d-lg-block h-nav-min'></div>
                    <div className='d-block d-lg-none' style={{minHeight:'150px'}}></div>

                    <div className='row m-0 flex-grow-1' >
                        <div className='col-12 col-md-6 col-lg-5 d-flex flex-column justify-content-center py-4'>
                            <h2 className='bold mb-4 fs-40'>
                                <Trans i18nKey='storia.title'></Trans>
                            </h2>

                            <p className='w-75 mb-5'><Trans i18nKey='storia.content'></Trans></p>
                            <div className='d-flex justify-content-center justify-content-md-start flex-wrap'>
                                <HashLink  to='/#caratteristiche' className='custom-btn btn-mas-blue-nb d-center me-4 mb-2 text-decoration-none'><Trans i18nKey='storia.btns.0.text'></Trans></HashLink> 
                                <HashLink  to='/#come_funziona' className='custom-btn btn-white d-center mb-2 text-decoration-none'><Trans i18nKey='storia.btns.1.text'></Trans></HashLink>  
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-7 d-center p-5'>
                            <img style={{minWidth:'280px', maxWidth:'700px'}} className='w-100' src={landingcar} alt='QR-Car' />
                        </div>
                    </div>
                </div>
            </section>

            {/* scopri */}
            {/* <section id={scopri.name} className='py-110' style={{backgroundColor:'#fafafa'}}>
                <div className='max-container m-auto px-sm-4'>
                    <div className='row m-0 mb-3'>
                        <div className='col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 my-auto'>           
                            <h4 className='text-uppercase dark-orange mb-3'><Trans i18nKey='scopri.title'></Trans></h4>
                            <h2 className='purple mb-3 fs-40'><Trans i18nKey='scopri.subtitle'></Trans></h2>
                        </div>
                    </div>
                    <div className='row m-0'>
                        {scopri.contents.map(content => (
                            <div key={content.id} className='col-12 col-md-6 p-4 d-center' >
                                <img src={content.imgUrl} alt="QR-Car Partner" className='' style={{maxWidth:'350px', width:'90%'}}/>
                            </div>
                        ))}
                    </div>
                </div>
            </section> */}

            {/* caratteristiche */}
            <section id={caratteristiche.name} className='max-container m-auto py-110 px-sm-4'>
                <div className='row m-0'>
                    <div className='col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 my-auto'>           
                        <h4 className='text-uppercase mas-blue mb-3'><Trans i18nKey='caratteristiche.title'></Trans></h4>
                        <h2 className='coral bold mb-3 fs-40'><Trans i18nKey='caratteristiche.subtitle'></Trans></h2>
                        {caratteristiche.contents.map(content => (
                            <p key={content.id} className='mb-3 fs-18 dark-chocolate'><Trans i18nKey={`caratteristiche.contents.${content.id}.text`}></Trans></p>
                        ))}
                    </div>
                </div>
            </section>

            {/* applicazioni */}
            <section id={applicazioni.name} className='bg-coral white position-relative py-110'>
                <div className='max-container m-auto py-5 my-5 ps-sm-4'>
                    <div className='row m-0 h-100'>
                        <div className='col-12 col-sm-10 col-md-6 col-lg-6 col-xl-5'>
                            <h4 className='text-uppercase mas-blue mb-3'><Trans i18nKey='applicazioni.title'></Trans></h4>
                            <h2 className='bold mb-3 fs-40'><Trans i18nKey='applicazioni.subtitle'></Trans></h2>
                            {applicazioni.contents.map(content => (
                                <p key={content.id} className='mb-3 fs-18'><Trans i18nKey={`applicazioni.contents.${content.id}.text`}></Trans></p>
                            ))}
                        </div>
                        <div className='col-12 d-flex d-md-none justify-content-end pe-0 mt-5'>
                            <img className='mw-100' src={dynamicImages[t('applicazioni.imgUrl')]} alt={applicazioni.subtitle} />
                        </div>
                    </div>
                </div>
                <div className='position-absolute d-md-flex justify-content-end align-items-center d-none top-0 right-0 w-40 h-100'>
                    <img className='mw-100' src={dynamicImages[t('applicazioni.imgUrl')]} alt={applicazioni.subtitle} /> 
                </div>
            </section>

            {/* cosa_serve */}
            <section id={cosa_serve.name} className='max-container m-auto py-110 px-sm-4'>
                <div className='row m-0'>
                    <div className='col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 my-auto'>
                        <h4 className='text-uppercase mas-blue mb-3'><Trans i18nKey='cosa_serve.title'></Trans></h4>
                        <h2 className='coral bold mb-3 fs-40'><Trans i18nKey='cosa_serve.subtitle'></Trans></h2>
                        <p className='mb-3 fs-18 dark-chocolate'><Trans i18nKey='cosa_serve.content'></Trans></p>
                    </div>
                </div>
                <div className='row m-0 mt-5'>
                    {cosa_serve.items.map(item => (
                        <div key={item.id} className='col-12 col-sm-6 col-lg-4 pe-md-5 py-4 text-center text-sm-start'>
                            <img className='mb-3' src={dynamicImages[item.imgUrl]} alt={item.title} />
                            <h4 className='coral bold'><Trans i18nKey={`cosa_serve.items.${item.id}.title`}></Trans></h4>
                            <p className='dark-chocolate m-0 pe-sm-5'><Trans i18nKey={`cosa_serve.items.${item.id}.content`}></Trans></p>
                        </div> 
                    ))}
                </div>
            </section>

            {/* come_funziona */}
            <section id={come_funziona.name} className='bg-coral white py-110'>
                <div className='max-container m-auto py-5 px-sm-4'>
                    <div className='row m-0'>
                        <div className='col-12 col-sm-10 col-md-6 col-lg-6 col-xl-5 mb-5'>
                            <h4 className='text-uppercase mas-blue mb-3'><Trans i18nKey='come_funziona.title'></Trans></h4>
                            <h2 className='bold mb-5 fs-40'><Trans i18nKey='come_funziona.subtitle'></Trans></h2>
                        </div>
                    </div>
                    <div className='row m-0'>
                        {come_funziona.items.map(item => (
                            <div key={item.id} className={`col-12 col-md-6 mb-5 ${item.id % 2 === 0 ? 'pe-md-5' : 'ps-md-5'}`}>
                                <img className='w-100 mb-4' src={dynamicImages[item.imgUrl]} alt={item.title} />
                                <h5 className='text-uppercase mb-3 bold'> <Trans i18nKey={`come_funziona.items.${item.id}.title`}></Trans></h5>
                                <h3 className='mb-3 bold'> <Trans i18nKey={`come_funziona.items.${item.id}.subtitle`}></Trans></h3>
                                <p className='fs-18'> <Trans i18nKey={`come_funziona.items.${item.id}.content`}></Trans></p>
                            </div> 
                        ))}
                    </div>
                </div>
            </section>

            {/* offerta */}
            <section id={offerta.name} className='max-container m-auto py-110'>
                <div className='row m-0 mb-5'>
                    <div className='col-12 my-auto text-center'>
                        <h4 className='text-uppercase mas-blue mb-3'><Trans i18nKey='offerta.title'></Trans></h4>
                        <h2 className='coral bold mb-3 fs-40'><Trans i18nKey='offerta.subtitle'></Trans></h2>
                        <p className='mb-3 fs-18 dark-chocolate'><Trans i18nKey='offerta.content'></Trans></p>
                    </div>
                </div>
                <div className='row m-0 d-flex justify-content-evenly'>
                    {offerta.items.map(item => {
                        return item.id === 1 ? 
                        <div key={item.id} className='col-12 col-md-6 col-lg-4 px-0 px-sm-4 px-md-3 d-center' >
                            <div className='popular bg-coral p-4 round-20 w-90 h-100 d-flex flex-column'>
                                <h2 className='white bold my-3 text-center fs-40'><Trans i18nKey={`offerta.items.${item.id}.title`}></Trans></h2>
                                <a href='mailto: info@board4birds.ch' className='mb-5 text-decoration-none'>
                                    <h4 className='mas-blue bold m-0 text-center fs-30'><Trans i18nKey={`offerta.items.${item.id}.price`}></Trans></h4>
                                </a>
                                <ul className='piani-popular p-0 white fs-18 list-unstyled mb-4'>
                                    {item.subItems.map(subItem => (
                                    <li key={subItem.id} className='mb-2'><Trans i18nKey={`offerta.items.${item.id}.subItems.${subItem.id}.content`}></Trans></li> 
                                    ))}
                                </ul>
                                <div className='bg-mas-blue-transparent mb-4 p-3 round-10'>
                                    <h5 className='text-uppercase mas-blue bold fs-14'><Trans i18nKey={`offerta.items.${item.id}.target.title`}></Trans></h5>
                                    <p className='mb-3 white'><Trans i18nKey={`offerta.items.${item.id}.target.content`}></Trans></p>
                                    <h5 className='text-uppercase mas-blue bold fs-14'><Trans i18nKey={`offerta.items.${item.id}.consigliato.title`}></Trans></h5>
                                    <p className='mb-3 white'><Trans i18nKey={`offerta.items.${item.id}.consigliato.content`}></Trans></p>
                                </div>
                                
                                <Link to={!user ? '/login' : '/cars'} className='round-50 mt-auto' style={{marginBottom:'17px', height:'44px'}}>
                                    <button className='custom-btn btn-mas-blue-outline w-100 h-100'>
                                        <Trans i18nKey={`offerta.items.${item.id}.btn`}></Trans>
                                    </button>
                                </Link> 
                            </div>
                        </div>
                        :
                        <div key={item.id} className='col-12 col-md-6 col-lg-4 px-0 py-4 p-sm-4 p-md-3 d-center' >
                            <div className='p-4 border-mas-blue round-20 w-90 h-100 d-flex flex-column'>
                                <h2 className='mas-blue bold my-3 text-center fs-40'><Trans i18nKey={`offerta.items.${item.id}.title`}></Trans></h2>
                                <a href='mailto: info@board4birds.ch' className='mb-5 text-decoration-none'>
                                    <h4 className='coral bold m-0 text-center fs-30'><Trans i18nKey={`offerta.items.${item.id}.price`}></Trans></h4>
                                </a>
                                <ul className='piani p-0 dark-chocolate fs-18 list-unstyled mb-4'>
                                    {item.subItems.map(subItem => (
                                    <li key={subItem.id} className='mb-2'><Trans i18nKey={`offerta.items.${item.id}.subItems.${subItem.id}.content`}></Trans></li> 
                                    ))}
                                </ul>
                                <div className='bg-mas-blue-transparent mb-4 p-3 round-10'>
                                    <h5 className='text-uppercase mas-blue fs-14 bold'><Trans i18nKey={`offerta.items.${item.id}.target.title`}></Trans></h5>
                                    <p className='mb-3 dark-chocolate'><Trans i18nKey={`offerta.items.${item.id}.target.content`}></Trans></p>
                                    <h5 className='text-uppercase mas-blue fs-14 bold'><Trans i18nKey={`offerta.items.${item.id}.consigliato.title`}></Trans></h5>
                                    <p className='mb-3 dark-chocolate'><Trans i18nKey={`offerta.items.${item.id}.consigliato.content`}></Trans></p>
                                </div>
                                
                                <Link to={!user ? '/login' : '/cars'} className='round-50 mt-auto' style={{height:'44px'}}>
                                    <button className='custom-btn btn-coral w-100 h-100'>
                                        <Trans i18nKey={`offerta.items.${item.id}.btn`}></Trans>
                                    </button>
                                </Link> 
                            </div>
                        </div>
                    })} 
                </div>
            </section>

            {/* personalizzazione */}
            <section className='max-container m-auto pt-5 px-sm-4'>
                {/* <div className='mx-3 pt-5 border-purple round-20'> */}
                <div className='enterprise mx-3 py-5 round-20'>
                    <div className='row m-0 mb-4'>
                        <div className='col-12 my-auto text-center d-flex flex-column align-items-center'>
                        <h4 className='text-uppercase mas-blue mb-3'><Trans i18nKey='personalizzazione.title'></Trans></h4>
                            <h2 className='coral bold mb-3 fs-40'><Trans i18nKey='personalizzazione.subtitle'></Trans></h2>
                            <p className='mb-3 fs-18 dark-chocolate w-max-600'><Trans i18nKey='personalizzazione.content'></Trans></p>
                        </div>
                    </div>
                    <div className='text-center'>
                        <p className='mb-3 fs-18 dark-chocolate'><Trans i18nKey='personalizzazione.items_title'></Trans></p>
                        <div className='d-flex flex-wrap justify-content-center mb-5'> 
                            {personalizzazione.items.map(item => (
                                <button key={item.id} className='custom-btn btn-tran-coral m-2 px-4'><Trans i18nKey={`personalizzazione.items.${item.id}.content`}></Trans></button>
                            ))}      
                        </div>
                        <div className='d-inline-block round-50 '>
                            <HashLink  to=''> <button className='custom-btn btn-coral'><Trans i18nKey='personalizzazione.btn'></Trans></button></HashLink>
                        </div> 
                    </div>
                </div>
                
            </section>
            
            {/* FAQ */}
            <section id={faq.name} className='bg-coral py-110 mt-5'>
                <div className='max-container m-auto py-5 px-sm-4'>
                    <div className='row m-0 white'>
                        <div className='col-12 col-sm-10 col-md-6 col-lg-6 col-xl-5 mb-5'>
                            <h4 className='text-uppercase mas-blue mb-3'><Trans i18nKey='faq.title'></Trans></h4>
                            <h2 className='bold mb-3 fs-40'><Trans i18nKey='faq.subtitle'></Trans></h2>
                            <p className='mb-3 fs-18'><Trans i18nKey='faq.content'></Trans></p>
                        </div>
                    </div>
                    <div className='row m-0'>
                        {faq.items.map(item => (
                            <div key={item.id} className='col-12 my-3'>
                                <div className='round-10 p-3 bg-white border-gray'>
                                    <div className='d-flex my-2 pointer' onClick={()=> handleCollapse(item.name)}>
                                        <span className='fs-18 coral bold me-3'><Trans i18nKey={`faq.items.${item.id-1}.num`}></Trans></span>
                                        <span className='fs-18 coral bold'><Trans i18nKey={`faq.items.${item.id-1}.title`}></Trans></span> 
                                        <i className={`fa fa-lg ms-auto d-center ${isOpen.includes(item.name) ? 'fa-arrow-up' : 'fa-arrow-down'}`} aria-hidden='true'></i>
                                    </div>
                                    <div className={`m-0 transition ${isOpen.includes(item.name) ? 'mh-500' : 'mh-0'}`}>
                                        {item.paragraphs.map(paragraph => (
                                            <p key={paragraph.id} className='fs-18 dark-chocolate'><Trans i18nKey={`faq.items.${item.id-1}.paragraphs.${paragraph.id}.content`}></Trans></p>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            
            {/* testimonial */}
            <section id={testimonial.name} className='max-container m-auto py-110 px-sm-4'>
                <div className='row m-0'>
                    <div className='col-12 col-md-6 mt-5'>
                        <h4 className='text-uppercase mas-blue mb-3'><Trans i18nKey='orizzonti.title'></Trans></h4>
                        <h2 className='coral bold mb-3 fs-40'><Trans i18nKey='orizzonti.subtitle'></Trans></h2>
                        {orizzonti.contents.map(content => (
                            <p key={content.id} className='mb-3 fs-18 dark-chocolate'><Trans i18nKey={`orizzonti.contents.${content.id}.text`}></Trans></p>
                        ))}
                        <div className='d-inline-block round-50 mt-5'>
                            <HashLink  to='/#contatti'> <button className='custom-btn btn-coral'><Trans i18nKey='orizzonti.btn'></Trans></button></HashLink>
                        </div> 
                    </div>
                    <div className='col-12 col-md-6 text-center text-md-end mt-5'>
                        <img src={virtualimg} alt='virtual Reality' className='position-relative w-100 h-100' style={{top:'9px', maxWidth:'450px'}}/>
                    </div>
                </div>
                {/* <div className='row m-0'>
                    <div className='col-12 col-sm-10 col-md-6 col-xl-5 my-auto'>
                        <h4 className='text-uppercase mas-blue mb-3'><Trans i18nKey='testimonial.title'></Trans></h4>
                        <h2 className='coral bold mb-3 fs-40'><Trans i18nKey='testimonial.subtitle'></Trans></h2>
                        {testimonial.contents.map(content => (
                            <p key={content.id} className='fs-18 dark-chocolate m-0'><Trans i18nKey={`testimonial.contents.${content.id}.text`}></Trans></p>
                        ))}
                    </div>
                </div>
                <div className='row m-0 mt-5'>
                    {testimonial.items.map(item => (
                        <div key={item.id} className='col-12 col-md-6 col-lg-4 px-0 py-4 p-sm-4 p-md-3 d-center' >
                            <div className='p-4 border-coral round-20 w-90 h-100 d-flex flex-column'>
                                <p className='italic dark-chocolate flex-1'><Trans i18nKey={`testimonial.items.${item.id}.quotation`}></Trans></p>
                                <div className='d-flex align-items-center'>
                                    <div className='circle overflow-hidden me-3' style={{width:'70px', height:'70px'}}>
                                        <img className='w-100 h-100' src={dynamicImages[item.imgUrl]} alt={`${item.fullname} avatar`} />
                                    </div>
                                    <div>
                                        <h5 className='coral bold'><Trans i18nKey={`testimonial.items.${item.id}.fullname`}></Trans></h5>
                                        {item.roles.map(role => (
                                            <p key={role.id} className='dark-chocolate m-0'><Trans i18nKey={`testimonial.items.${item.id}.roles.${role.id}.role_name`}></Trans></p>
                                        ))}
                                    </div>
                                </div>               
                            </div>
                        </div> 
                    ))}   
                </div> */}
            </section>

            {/* contatti */}
            <section id={contatti.name} className='bg-coral py-110'>
                <div className='max-container m-auto py-5 white fs-18 px-sm-4'>
                    <div className='row m-0 mt-3'>
                        <div className='col-12 col-sm-10 col-md-6 col-lg-8 col-xl-8 mb-5'>
                        <h4 className='text-uppercase mas-blue mb-3'><Trans i18nKey='contatti.title'></Trans></h4>
                            <h2 className='fs-40 bold'><Trans i18nKey='contatti.subtitle_first'></Trans></h2>
                            <h2 className='mb-4 fs-40 bold'><Trans i18nKey='contatti.subtitle_second'></Trans></h2>
                        </div>
                    </div>
                    <div className='row m-0 '>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <div className='d-flex mb-3'>
                                <i className='fa fa-map-marker fa-lg mas-blue me-3 mt-1' aria-hidden='true'></i>
                                <div>
                                    <h5 className='text-uppercase mas-blue mb-2 bold'><Trans i18nKey='contatti.address.title'></Trans></h5>
                                    <ul className='list-unstyled'>
                                        <li className='mb-1'><Trans i18nKey='contatti.address.company_name'></Trans></li>
                                        <li className='mb-1'><Trans i18nKey='contatti.address.full_addr'></Trans></li>
                                        <li className='mb-1'><Trans i18nKey='contatti.address.tel'></Trans></li>
                                        <li className='mb-1 '>Email: <a className='white' href={`mailto:${contatti.address.email}`}><Trans i18nKey='contatti.address.email'></Trans></a></li>
                                        <li className='mb-1'>Website: <a className='white' href={contatti.address.website}><Trans i18nKey='contatti.address.website'></Trans></a></li>
                                    </ul>
                                </div>
                            </div>  
                        </div>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <div className='d-flex'>
                                <i className='fa fa-envelope fa-lg mas-blue me-3 mt-1' aria-hidden='true'></i>
                                <div>
                                    <h5 className='text-uppercase mas-blue mb-2 bold'><Trans i18nKey='contatti.support_info.title'></Trans></h5>
                                    <ul className='list-unstyled'>
                                        <li className='mb-1 text-decoration-underline'><a className='white' href={`mailto:${contatti.support_info.email}`}><Trans i18nKey='contatti.support_info.email'></Trans></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Footer */}
            <section className='bg-dark-sienna'>
                <div className='max-container m-auto py-5 px-sm-4'>
                    <div className='row m-0 white'>
                        <div className='col-10 col-sm-9 col-lg-3 mb-3'>
                            <HashLink to='/#'> <img src={imglogowhite} alt='QR-Car logo' className='mb-3' style={{ height:'69px'}}/> </HashLink>
                            {/* <HashLink to='/#' className='text-decoration-none'> <h3 className='white mb-4 bold'>QR-Car</h3> </HashLink> */}
                            <p className='pe-md-5' style={{maxWidth:'400px'}}><Trans i18nKey='footer.description'></Trans></p>
                        </div>
                        <div className='col-6 col-sm-4 col-lg-3 mb-3 ps-lg-5'>
                            <ul className='list-unstyled boder'>
                                <li className='coral bold mb-3'><Trans i18nKey='footer.links.0.title'></Trans></li>
                                <li className='mb-3'> <HashLink className='footer-link' to='/#caratteristiche'><Trans i18nKey='footer.links.0.items.0.text'></Trans></HashLink> </li>
                                <li className='mb-3'> <HashLink className='footer-link' to='/#come_funziona'><Trans i18nKey='footer.links.0.items.1.text'></Trans></HashLink> </li>
                                <li className='mb-3'> <HashLink className='footer-link' to='/#cosa_serve'><Trans i18nKey='footer.links.0.items.2.text'></Trans></HashLink> </li>
                            </ul>
                        </div>
                        <div className='col-6 col-sm-4 col-lg-3 mb-3 ps-lg-5'>
                            <ul className='list-unstyled boder'>
                                <li className='coral bold mb-3'><Trans i18nKey='footer.links.1.title'></Trans></li>
                                <li className='mb-3'> <HashLink className='footer-link' to='/#offerta'><Trans i18nKey='footer.links.1.items.0.text'></Trans></HashLink> </li>
                                <li className='mb-3'> <HashLink className='footer-link' to='/#faq'><Trans i18nKey='footer.links.1.items.1.text'></Trans></HashLink> </li>
                                <li className='mb-3'> <HashLink className='footer-link' to='/#testimonial'><Trans i18nKey='footer.links.1.items.2.text'></Trans></HashLink> </li>
                            </ul>
                        </div>
                        <div className='col-6 col-sm-4 col-lg-3 mb-3 ps-lg-5'>
                            <ul className='list-unstyled'>
                                <li className='coral bold mb-3'><Trans i18nKey='footer.links.2.title'></Trans></li>
                                <li className='mb-3'><Link className='footer-link' to='/privacy-policy'><Trans i18nKey='footer.links.2.items.0.text'></Trans></Link></li>
                                <li className='mb-3'><Link className='footer-link' to='/privacy-policy'><Trans i18nKey='footer.links.2.items.1.text'></Trans></Link></li>
                                <li className='mb-3'><Link className='footer-link' to='/terms-conditions'><Trans i18nKey='footer.links.2.items.2.text'></Trans></Link></li>
                            </ul>
                        </div>
                        <div className='col-12 text-center text-sm-start'>
                            <p className='m-0'><Trans i18nKey='footer.copy_right'></Trans></p>
                        </div>
                    </div>
                </div>
            </section>

            {/* {window.pageYOffset  && 
                <div className='scroll-up' onClick={() => window.scrollTo(0, 0)}>
                    <HashLink className='btn-orange d-center transition circle text-decoration-none w-100 h-100'  to='/' >
                        <i className='fa fa-arrow-up' aria-hidden='true'></i>
                    </HashLink>
                </div>
            } */}
            {/* <ScrollButton route='/' /> */}
            
        </main>
    )
}

export default Home;