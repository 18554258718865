import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../services/AuthService';

const Logout = () => {

    const Auth = new AuthService();
    const navigate = useNavigate();

    useEffect(() => {
        Auth.logout();
        navigate("/");
    }, []);

    return null;
}

export default Logout;