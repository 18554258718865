import { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { format } from 'date-fns'

const Filter = ({showFilter, applyFilter}) => {

    const [ownerFilter, setOwnerFilter] = useState("");
    const [houseIdFilter, setHouseIdFilter] = useState("");
    const [startDateFilter, setStartDateFilter] = useState(null);
    const [endDateFilter, setEndDateFilter] = useState(null);
    const [regionFilter, setRegionFilter] = useState("");
    const [zipCodeFilter, setZipCodeFilter] = useState("");
    const [addressFilter, setAddressFilter] = useState("");
    const [countryFilter, setCountryFilter] = useState("");
    const [carNameFilter, setCarNameFilter] = useState("");

    const filterInputs = {
        ownerFilter, 
        houseIdFilter,
        carNameFilter,
        countryFilter,
        startDateFilter: new Date(startDateFilter).getTime() / 1000, 
        endDateFilter: new Date(endDateFilter).getTime() / 1000, 
        // regionFilter, 
        // zipCodeFilter, 
        // addressFilter, 
    }
    const filterQueryObj = {
        owner: ownerFilter,
        registration_id: houseIdFilter,
        car_name: carNameFilter,
        start_date: startDateFilter,
        end_date: endDateFilter,
        country: countryFilter 
    };

    const handleHouseFilter = () => {
        applyFilter(filterQueryObj)
    }

    

    const handleResetFilter = () => {
        resetStates();
        applyFilter({});
    }

    const resetStates = () => {
        setOwnerFilter("");
        setHouseIdFilter("");
        setCarNameFilter("");
        setStartDateFilter(null);
        setEndDateFilter(null);
        setRegionFilter("");
        setZipCodeFilter("");
        setAddressFilter("");
        setCountryFilter("");
    }
    
    return (  
        <>
            {showFilter ?
                <div className='mb-5 bg-silverS'>
                    <ul className='row m-0 list-unstyled dark-purple'>
                        {/* OWNER FILTER */}
                        <li className='col-12 col-md-6 col-lg-4 col-xl-3 py-3'>
                            <label htmlFor='owner' className='mb-2 bold'>Owner</label>
                            <input 
                                type='text' 
                                id='owner'  
                                name='owner'
                                placeholder='Insert Owner ID' 
                                className='custom-input d-block'
                                value={ownerFilter}
                                onChange={e => {
                                    setOwnerFilter(e.target.value);                                
                                }}
                            />   
                        </li>
        
                        {/* PLATE ID FILTER */}
                        <li className='col-12 col-md-6 col-lg-4 col-xl-3 py-3'>
                            <label htmlFor='plate_id' className='mb-2 bold'>Plate ID</label>
                            <input 
                                type='text' 
                                id='plate_id'  
                                name='plate_id'
                                placeholder='Insert Plate ID' 
                                className='custom-input d-block'
                                value={houseIdFilter}
                                onChange={e => setHouseIdFilter(e.target.value)}
                            />   
                        </li>

                        {/* CAR NAME FILTER */}
                        <li className='col-12 col-md-6 col-lg-4 col-xl-3 py-3'>
                            <label htmlFor='car_name' className='mb-2 bold'>Car Name</label>
                            <input 
                                type='text' 
                                id='car_name'  
                                name='car_name'
                                placeholder='Insert Car Name' 
                                className='custom-input d-block'
                                value={carNameFilter}
                                onChange={e => setCarNameFilter(e.target.value)}
                            />   
                        </li>

                        {/* COUNTRY FILTER */}
                        <li className='col-12 col-md-6 col-lg-4 col-xl-3 py-3'>
                            <label htmlFor='country' className='mb-2 bold'>Country</label>
                            <input 
                                type='text' 
                                id='country'  
                                name='country' 
                                placeholder='Insert Country' 
                                className='custom-input d-block'
                                value={countryFilter}
                                onChange={e => setCountryFilter(e.target.value)}
                            />   
                        </li>
        
                        {/* DATE START FILTER */}
                        <li className='col-12 col-md-6 col-lg-4 col-xl-3 py-3'>
                            <label htmlFor='date_start' className='mb-2 bold'>Date start</label>
                            <ReactDatePicker 
                                type='date' 
                                id='date_start'  
                                name='date_start' 
                                className='custom-input d-block' 
                                selected={startDateFilter ? new Date(startDateFilter) : null} 
                                dateFormat="EEE MMMM dd yyyy"
                                placeholderText='Insert Start Date'
                                value={startDateFilter}
                                onChange={(date) => {
                                    const selected_date = format(date, "EEE MMMM dd yyyy")
                                    setStartDateFilter(selected_date);
                                }} 
                            />  
                        </li>
        
                        {/* DATE END FILTER */}
                        <li className='col-12 col-md-6 col-lg-4 col-xl-3 py-3'>
                            <label htmlFor='date_end' className='mb-2 bold'>Date end</label>
                            <ReactDatePicker 
                                type='date' 
                                id='date_end'  
                                name='date_end' 
                                className='custom-input d-block' 
                                selected={endDateFilter ? new Date(endDateFilter) : null}  
                                dateFormat="EEE MMMM dd yyyy"
                                placeholderText='Insert End Date'
                                value={endDateFilter}
                                onChange={(date) => {
                                    const selected_date = format(date, "EEE MMMM dd yyyy")
                                    setEndDateFilter(selected_date);
                                }} 
                            />   
                        </li>
                    </ul>
                    <div className='row m-0'>
                        <div className='col-12 d-flex justify-content-end my-3'>
                            <button className='custom-btn btn-rose-gold-fill me-3' onClick={handleResetFilter}>Reset</button>
                            <button className='custom-btn btn-mas-blue-outline' onClick={handleHouseFilter}>Apply</button>
                        </div>
                    </div>
                </div>
            : null}
        </>
    );
}
 
export default Filter;