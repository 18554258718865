import { useEffect, useState } from 'react';
import { Link, useOutletContext, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ReactCardFlip from 'react-card-flip';
import { Skeleton } from '@mui/material';
import { Slider } from '../../layouts/Slider';
import QRCode from '../../layouts/QRCode';
// import Pagination from '../../layouts/Pagination';
import {houseDetailHeader} from '../../data/constant';
import ItemService from '../../services/ItemService';
import DocumentService from '../../services/DocumentService';
import imgcarfull from '../../assets/images/car_fullSize@2x.png'
import { toast } from 'react-toastify';
import Accordion from '../../layouts/Accordion';
import ModalAddDocument from './ModalAddDocument';
import infoImg from '../../assets/images/info.png'
import infoIconImg from '../../assets/images/info-icon.png'
import ModalAddImage from './ModalAddImage';
import ModalDocumentDetail from './ModalDocumentDetail';
import ModalDocumentShare from './ModalDocumentShare';
import AuthService from '../../services/AuthService';
import { explorer_domain } from '../../services/env';
import moment from 'moment'
 
const Details = () => {

    const [ loadSkeleton, setLoadSkeleton ] = useState(true);
    const [ qr, setQr ] = useState(null);
    const [ flip, setFlip ] = useState(false);
    const [ perPage, setPerPage ] = useState(5);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ car, setCar ] = useState({data:{images:{}, country:{}}});
    const [ documents, setDocuments ] = useState([]);
    const [ filteredDocuments, setFilteredDocuments ] = useState([]);
    const [ config ] = useOutletContext();
    const [ activeAddImgModal, setActiveAddImgModal ] = useState(false);
    const [ activeAddDocModal, setActiveAddDocModal ] = useState(false);
    const [ filters, setFilters ] = useState([]);
    const [ activeDescDocModal, setActiveDescDocModal ] = useState(false);
    const [ activeDocument, setActiveDocument ] = useState({});
    const [ activeShareDocModal, setActiveShareDocModal ] = useState(false);
    const [ shareDoc, setShareDoc ] = useState("");

    const Item = new ItemService();
    const Document = new DocumentService();
    const Auth = new AuthService();
    const params = useParams();

    useEffect(() => {
        // dispatch(getSingleHouse(id));
        // getHouseQRCode(id).then(response => setQr(response));
        loadData();
    }, []);

    const loadData = () => {
        setPerPage(5);
        setCurrentPage(1);
        let promises = [];
        promises.push(Item.get(params.id))
        promises.push(Document.getByItem(params.id))
        Promise.all(promises).then((results) => {
            setCar(results[0].data.item);
            setDocuments(results[1].data.documents);
            setFilteredDocuments(results[1].data.documents)
            setLoadSkeleton(false);
        })
        .catch((err) => {
          toast.error("Unable to fetch car.")
        })
    }

    useEffect(() => {
      if(filters.length === 0) {
        setFilteredDocuments(documents)
        return
      }
      let filteredDocuments = documents.filter((e) => filters.includes(e.metadata.type) )
      setFilteredDocuments(filteredDocuments)
    }, [filters])

    const handleDownloadLabel = () => {
      Item.getCertificate(car.tx_hash).then((res) => {
        var evt = new MouseEvent('click', {
          view: window,
          bubbles: false,
          cancelable: true
        });
        var blob = new Blob([res.data], { type: "application/pdf" });
        var url = window.URL || window.webkitURL;
        var link = url.createObjectURL(blob);
        var a = document.createElement('a');
        a.setAttribute('download', 'certificate.pdf');
        a.setAttribute('href', link);
        a.setAttribute('target', '_blank');
      
        a.dispatchEvent(evt);
      })

    }

    const handleShowDoc = (doc) => {
      setActiveDocument(doc);
      setActiveDescDocModal(true);
    }

    const handleShareDoc = (doc) => {
      setShareDoc("");
      setActiveShareDocModal(true);
    }

  return (
    <>
        <main className='max-container m-auto p-container'>
          <Helmet>
              <title>QR-Car | Car details</title>
          </Helmet>

          <div className='row m-0 mb-4'>
            {loadSkeleton ? 
              <h4 className='col-12 col-sm-6 my-auto dark-purple bold'>
                  <Skeleton variant='rectangle' sx={{width:'120px', height:'40px'}} className='my-1'/>
              </h4>
            : 
              <h4 className='col-12 col-sm-6 py-2 my-auto dark-purple bold'>Car details</h4>
            }
          </div>
          
          <section className="row m-0">
            <div className="col-12 col-lg-5 position-relative p-0 p-md-3 h-500">
              {(car.data && car.data.images && car.data.images.length === 0) || (car.data && !car.data.images) ?
                <>
                  { loadSkeleton ?
                    <div className='w-100 h-100 overflow-hidden'> 
                      <Skeleton variant='rectangle' width={'100%'} height={'75%'}/>
                      <div className='h-25 d-flex pt-2 justify-content-between'>
                        <Skeleton variant='rectangle' width={'24%'} height={'100%'}/>
                        <Skeleton variant='rectangle' width={'24%'} height={'100%'}/>
                        <Skeleton variant='rectangle' width={'24%'} height={'100%'}/>
                        <Skeleton variant='rectangle' width={'24%'} height={'100%'}/>
                      </div> 
                    </div> 
                  : 
                    <img src={imgcarfull} alt="slider" className='w-100 h-100 cover'/>
                  }
                </>
              :
                <> 
                  { loadSkeleton || car.data.images.length === 0 ? 
                    <div className='w-100 h-100 overflow-hidden'> 
                      <Skeleton variant='rectangle' width={'100%'} height={'75%'}/>
                      <div className='h-25 d-flex pt-2 justify-content-between'>
                        <Skeleton variant='rectangle' width={'24%'} height={'100%'}/>
                        <Skeleton variant='rectangle' width={'24%'} height={'100%'}/>
                        <Skeleton variant='rectangle' width={'24%'} height={'100%'}/>
                        <Skeleton variant='rectangle' width={'24%'} height={'100%'}/>
                      </div>
                    </div>  
                  : 
                    <Slider images={car.data.images}/>
                  } 
                </>
              }
            </div>
            <div className="col-12 col-lg-7 my-3 my-md-0 p-md-3 overflow-hidden">
              <ReactCardFlip isFlipped={flip} flipDirection="horizontal">
                <div className='w-100 h-100 position-relative py-5 py-md-0 bg-white'>
                  { loadSkeleton ? 
                    <div className="position-absolute top-0 right-0">
                      <Skeleton variant='circle' sx={{width:'140px', height:'40px', borderRadius:'50px'}}/>
                    </div> 
                  :
                    <button className='custom-btn btn-mas-blue-outline position-absolute top-0 right-0' onClick={() => setFlip(!flip)}>Car details</button>
                  }
                  <div className="row m-0 h-100 d-center bg-white pt-0 pt-md-4">
                    <div className=' col-12 col-sm-11 col-md-9 col-lg-12 col-xl-9 py-4 pt-md-5 px-lg-0'>
                      <div className="round-20 w-100 pb-3 overflow-auto qr-card-shadow"> 
                        <div className="d-flex overflow-auto w-min-500 bg-white">
                          <div className="d-center">
                            { loadSkeleton ? 
                              <Skeleton variant='rectangle' width={140} height={140} className='m-3'/> 
                            : 
                              <img src={car.qrcode} alt="Car-QR-Code" className='qube-qr'/>
                            }
                          </div>
                          <div className='px-2 py-3 d-flex align-items-center w-100'>
                            <ul className='list-unstyled m-0 w-100'>
                                {/* ID registration */}
                                <li className='my-2'>
                                  { loadSkeleton ? 
                                    <>
                                      <Skeleton variant='text'  className='m-0 fs-18 bold w-25'/>
                                      <Skeleton variant='text'  className='m-0 w-50'/>
                                    </>
                                  : 
                                    <>
                                      <h5 className='coral m-0 fs-18 bold'>Plate ID</h5>
                                      <p className='m-0 dark-purple'>{car.data.registration_id}</p>
                                    </>
                                  }
                                </li>

                                {/* Data creation */}
                                <li className='my-2'>
                                  { loadSkeleton ? 
                                    <>
                                      <Skeleton variant='text'  className='m-0 fs-18 bold w-25'/>
                                      <Skeleton variant='text'  className='m-0 w-50'/>
                                    </>
                                  : 
                                    <>
                                      <h5 className='coral m-0 fs-18 bold'>Registration date</h5>
                                      <p className='m-0 dark-purple'>{moment(car.data.date).format('YYYY-MM-DD')}</p>
                                    </>
                                  }
                                </li>

                                {/* Country */}
                                <li className='my-2'>
                                  { loadSkeleton ? 
                                    <>
                                      <Skeleton variant='text'  className='m-0 fs-18 bold w-25'/>
                                      <Skeleton variant='text'  className='m-0 w-75'/>
                                    </>
                                  : 
                                    <>
                                      <h5 className='coral m-0 fs-18 bold'>Country</h5>
                                      {/* <p className='m-0 dark-purple'>{attributes.address} {attributes.geo_zip}, {attributes.geo_region}, {attributes.geo_state}</p> */}
                                      <p className='m-0 dark-purple'>{car.data.country.label}</p>
                                    </>
                                  }
                                </li>

                                {/* Transaction */}
                                <li className='my-2'>
                                  { loadSkeleton ? 
                                    <>
                                      <Skeleton variant='text'  className='m-0 fs-18 bold w-25'/>
                                      <Skeleton variant='text'  className='m-0 w-100'/>
                                    </>
                                  : 
                                    <>
                                      <h5 className='coral m-0 fs-18 bold'>Transaction</h5>
                                      <p className='m-0 dark-purple word-break'>
                                        <a href={`${explorer_domain}/tx/${car.tx_hash}/internal-transactions`} className='dark-purple word-break' rel="noreferrer" target='_blank'>
                                            {car.tx_hash}
                                        </a>
                                      </p>
                                    </>
                                  }
                                </li>
                            </ul>
                          </div>
                        </div>
                        <div className='overflow-hidden round-20 w-min-500'>
                          <div className="d-flex justify-content-start justify-content-sm-center align-items-center round-20 p-2">
                            { loadSkeleton ?
                              <>
                                <Skeleton variant='circle' sx={{width:'140px', height:'40px', borderRadius:'50px', marginRight:'1rem'}} />
                                <Skeleton variant='circle' sx={{width:'140px', height:'40px', borderRadius:'50px', marginRight:'1rem'}} />
                                <Skeleton variant='circle' sx={{width:'140px', height:'40px', borderRadius:'50px', marginRight:'1rem'}} />
                                <Skeleton variant='circle' sx={{width:'140px', height:'40px', borderRadius:'50px', marginRight:'1rem'}} />
                              </>
                            : 
                              <>
                                <QRCode qr_url={car.qrcode} number="1" title="Download QR"/>
                                <div className='mx-3'>
                                  
                                  <QRCode qr_url={Auth.getDomain() + "api/v1/blockchain/nft/" + car.nft_token_id + "/"} number="2" title="Download NFT"/>
                                </div>
                                <button className='d-center custom-btn btn-light-purple' onClick={handleDownloadLabel}>Download Certificate</button>
                                { car.is_public ? (
                                  <Link to={`/car-public-information/${car.tx_hash}`} target="_blank" className='mx-3 text-decoration-none'>
                                    <button className='d-center custom-btn btn-light-purple'>Car Details</button>
                                  </Link>
                                ) : (
                                  <Link to={`/car-information/${car.id}`} target="_blank" className='mx-3 text-decoration-none'>
                                    <button className='d-center custom-btn btn-light-purple'>Car Details</button>
                                  </Link>
                                )}
                                
                              </>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='w-100 h-100 d-flex flex-column justify-content-between position-relative py-5 py-md-0 bg-white'>
                  <button className='custom-btn btn-mas-blue-outline position-absolute top-0 right-0' onClick={() => setFlip(!flip)}>Show QR</button>
                  <div className=''>
                    <h4 className='coral bold fs-18 text-uppercase'>PLATE ID</h4>
                    <p className='dark-purple bold fs-16'> {car.data.registration_id} </p>
                  </div>

                  <div className=''>
                    <h4 className='coral bold fs-18 text-uppercase'>VEHICLE NAME</h4>
                    <p className='dark-purple bold fs-16'> {car.data.car_name} </p>
                  </div>

                  <div className=''>
                    <h4 className='coral bold fs-18 text-uppercase'>REGISTRATION DATE</h4>
                    {/* <p className='dark-purple bold fs-16'> {format(new Date(attributes.regdate), 'dd/MM/yyyy')} </p> */}
                    <p className='dark-purple bold fs-16'> {car.data.date} </p>
                  </div>

                  <div className='d-flex flex-wrap'>
                    {/* STATE */}
                    <div className='me-5'>
                      <h5 className='coral bold fs-20 text-uppercase'>COUNTRY</h5>
                      <p className='dark-purple bold fs-16'> {car.data.country.label} </p>
                    </div>

                  </div>

                  <div className=''>
                    <h4 className='coral bold fs-18 text-uppercase'>OWNER BLOCKCHAIN ADDRESS</h4>
                    {/* {attributes.owner.map((own, index) => (
                      <p key={index} className='dark-purple bold fs-16'>{own}</p>
                    ))} */}
                    <p className='dark-purple bold fs-16 word-break'>{car.data.owner}</p>
                  </div>

                  <div className=''>
                    <h4 className='coral bold fs-18 text-uppercase'>TxID OF REGISTRATION</h4>
                    <p className='dark-purple bold fs-16 m-0 word-break'>{car.tx_hash}</p>
                  </div>
                </div>
              </ReactCardFlip>    
            </div>
          </section>

          <section className="row m-0 mt-5">
            <div className="col-12 col-lg-3 col-xl-3">
              { loadSkeleton ? 
                <h4 className='my-auto dark-purple bold mb-4' style={{padding:'2px 0'}}>
                    <Skeleton variant='rectangle' sx={{width:'120px', height:'40px'}} className='p-2'/>
                </h4>
              : 
                <h4 className=' py-2 my-auto dark-purple bold mb-4'>Categories</h4>
              }
              <div className="py-1 row">
                <Accordion setFilters={setFilters} loading={loadSkeleton} documents={filteredDocuments} setCurrentPage={setCurrentPage}/>
              </div>
            </div>
                
            <div className="col-12 col-lg-9 col-xl-9 ps-lg-5">
              
              <div className='row mb-4'>
                { loadSkeleton ? 
                  <h4 className='col-12 col-sm-5 col-md-6 my-auto dark-purple bold px-3' style={{padding:'2px 0'}}>
                      <Skeleton variant='rectangle' sx={{width:'120px', height:'40px'}} className='p-2'/>
                  </h4>
                : 
                  <h4 className='col-12 col-sm-5 col-md-6 py-2 my-auto dark-purple bold'>Documents</h4>
                }

                <div className='col-12 col-sm-7 col-md-6'>
                  <div className='d-flex justify-content-end overflow-auto'>

                    {loadSkeleton ? 
                      <Skeleton variant='circle' sx={{width:'140px', height:'40px', borderRadius:'50px', marginRight:'1rem'}} />
                      :
                      <button className='custom-btn btn-mas-blue-fill me-3' onClick={() => setActiveAddImgModal(true)}>Add image</button>
                    }

                    {loadSkeleton ? 
                      <Skeleton variant='circle' sx={{width:'140px', height:'40px', borderRadius:'50px'}} />
                      :
                      <button className='custom-btn btn-coral' disabled={documents.length >= config.max_allowed_images} onClick={() => setActiveAddDocModal(true)}>Add document</button>
                    }
                    
                  </div>
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-12 p-0 overflow-hidden'>
                  <div className='t-head mb-2 p-1'>   
                    <div className='px-2'>
                      {loadSkeleton ? 
                        <Skeleton variant='rectangle' height={44} className='rounded m-0'/> 
                      :
                        <ul className='house-header mb-0'>
                          {houseDetailHeader.map( (header, index) => {
                              // <li key={index} className='house-header-content px-3' style={{width:header.width}}>
                              //     {header.name}
                              // </li>

                              const item = index === 0 ? 
                            <li key={index} className='house-header-content dark-purple px-3' style={{maxWidth:header.width, width:header.width}}>
                                {header.name}
                            </li> :
                            <li key={index} className='house-header-content dark-purple px-3' style={{width:header.width}}>
                                {header.name}
                            </li>
                            return item;
                          })}
                        </ul>
                      }
                    </div>

                    <div className='house-list-wrapper p-2'>
                      {filteredDocuments.length === 0 ?
                        <></>
                      :
                        <> 
                          {loadSkeleton === true || filteredDocuments.length === 0 ?                           
                            <>
                              {filteredDocuments.map((d, index) => (
                                  <Skeleton  key={index} height={110} sx={{ my:-2 }} />
                              ))}
                            </>
                          : 
                            <>
                              {filteredDocuments.map((document, index) => (
                                <ul key={index} className='house-list-item'>
                                  <li className='house-content bg-mas-blue-light' style={{width:'67px', maxWidth:'67px'}}>
                                    <img src={infoImg} alt="QR-car" />
                                  </li>
                                  <li className='house-content word-break ps-4 pe-3 olive justify-content-center' style={{width:'180px'}}>
                                    {/* <span>{document.data.name.replace(/\.[^/.]+$/, "")}</span> */}
                                    <span>{document.metadata.name}</span>
                                  </li>
                                  <li className='house-content word-break px-3' style={{width:'180px'}}>
                                    <span>{document.metadata.description}</span>
                                  </li>
                                  <li className='house-content word-break px-3' style={{width:'180px'}}>
                                    <span>{document.metadata.type}</span>
                                  </li>
                                  <li className='house-content px-3' style={{width:'180px'}}>
                                    <a  className='round-50' href={document.content} target='_blank' download>
                                      <button className='custom-btn btn-light-purple'>Download</button>
                                    </a>
                                  </li> 

                                  <li className='house-content px-3' style={{width:'90px'}}>
                                    <img className='pointer' src={infoIconImg} alt="information" onClick={() => handleShowDoc(document)} />
                                  </li>
                                  
                                  
                                  <li className='house-content px-3' style={{width:'90px'}}>
                                    <i className="fa fa-share-alt fa-lg p-2 pointer" aria-hidden="true" onClick={() => handleShareDoc(document)}></i>
                                  </li>

                                </ul> 
                              ))}
                            </>
                          } 
                        </>
                      } 
                    </div>
                  </div>
                </div>
              </div>                

            </div>
          </section>

          {/* <section className="row mt-3 flex-column-reverse flex-lg-row m-0">
            <div className="col-12 col-lg-3 col-xl-3">
              {loadSkeleton ?
                <Skeleton variant='rectangle' sx={{maxWidth:'150px', height:'40px'}}/>
              :
              <div className='d-flex align-items-center' style={{maxWidth:'150px'}} >
                  <label className="me-3 text-nowrap">Show</label>
                  <select className="form-select" aria-label="select number of rows" value={perPage} onChange={handlePerPage} >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                  </select>
              </div> 
              }
            </div>
            <div className='col-12 col-lg-9 col-xl-9 ps-lg-5 d-flex justify-content-center overflow-hidden mb-4 mb-lg-0'>
              <Pagination totalHouses={documents.length} currentPage={currentPage} perPage={perPage} onPageChange={handlePageChange} />
            </div>
          </section> */}

          {/* <Modal 
            activeDocModal={activeDocModal} document={showDoc} 
            activeShareModal={activeShareModal} shareDoc={shareDoc}
            activeAddDocModal= {activeAddDocModal}
            activeAddImgModal= {activeAddImgModal}
            // activeSliderModal= {activeSliderModal} activeImage={activeImage}
            closeModal={closeModal}
          /> */}

          <ModalAddDocument reload={loadData} addedDocuments={documents.length} itemId={car.id} modalOpened={activeAddDocModal} platformConfig={config} closeModal={() => setActiveAddDocModal(false)} />
          <ModalAddImage images={car.data.images} reload={loadData} itemId={car.id} modalOpened={activeAddImgModal} platformConfig={config} closeModal={() => setActiveAddImgModal(false)} />
          <ModalDocumentDetail modalOpened={activeDescDocModal} document={activeDocument} closeModal={() => setActiveDescDocModal(false)} />
          <ModalDocumentShare modalOpened={activeShareDocModal} shareDoc={shareDoc} closeModal={() => setActiveShareDocModal(false)} />
        </main>
      
    </>
  )
}

export default Details;