import { useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Skeleton } from '@mui/material';
import { Slider } from '../../layouts/Slider';
import QRCode from '../../layouts/QRCode';
// import Pagination from '../../layouts/Pagination';
import {houseDetailHeader} from '../../data/constant';
import ItemService from '../../services/ItemService';
import DocumentService from '../../services/DocumentService';
import imgcarfull from '../../assets/images/car_fullSize@2x.png'
import { toast } from 'react-toastify';
import Accordion from '../../layouts/Accordion';
import infoImg from '../../assets/images/info.png'
import infoIconImg from '../../assets/images/info-icon.png'
import ModalDocumentDetail from './ModalDocumentDetail';
import ModalDocumentShare from './ModalDocumentShare';
import avatarImg from '../../assets/images/avatar.png'
import { explorer_domain } from '../../services/env';
import moment from 'moment'
 
const PublicInformation = () => {

    const [ loadSkeleton, setLoadSkeleton ] = useState(true);
    const [ perPage, setPerPage ] = useState(5);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ car, setCar ] = useState({data:{images:{}, country:{}}});
    const [ documents, setDocuments ] = useState([]);
    const [ filteredDocuments, setFilteredDocuments ] = useState([]);
    const [ filters, setFilters ] = useState([]);
    const [ activeDescDocModal, setActiveDescDocModal ] = useState(false);
    const [ activeDocument, setActiveDocument ] = useState({});
    const [ activeShareDocModal, setActiveShareDocModal ] = useState(false);
    const [ shareDoc, setShareDoc ] = useState("");

    const Item = new ItemService();
    const Document = new DocumentService();
    const params = useParams();

    useEffect(() => {
        // dispatch(getSingleHouse(id));
        // getHouseQRCode(id).then(response => setQr(response));
        loadData();
    }, []);

    const loadData = () => {
        setPerPage(5);
        setCurrentPage(1);
        let promises = [];
        promises.push(Item.getPublic(params.tx_hash))
        promises.push(Document.getPublicByItem(params.tx_hash))
        Promise.all(promises).then((results) => {
            setCar(results[0].data.item);
            setDocuments(results[1].data.documents);
            setFilteredDocuments(results[1].data.documents)
            setLoadSkeleton(false);
        })
        .catch((err) => {
          toast.error("Unable to fetch car.")
        })
    }

    useEffect(() => {
      if(filters.length === 0) {
        setFilteredDocuments(documents)
        return
      }
      let filteredDocuments = documents.filter((e) => filters.includes(e.metadata.type) )
      setFilteredDocuments(filteredDocuments)
    }, [filters])

    const handleShowDoc = (doc) => {
      setActiveDocument(doc);
      setActiveDescDocModal(true);
    }

    const handleShareDoc = (doc) => {
      setShareDoc("");
      setActiveShareDocModal(true);
    }

  return (
    <>
        <main className='max-container m-auto p-container'>
          <Helmet>
              <title>QR-Car | Car details</title>
          </Helmet>

          <div className='row m-0 mb-4'>
            {loadSkeleton ? 
              <h4 className='col-12 col-sm-6 my-auto dark-purple bold'>
                  <Skeleton variant='rectangle' sx={{width:'120px', height:'40px'}} className='my-1'/>
              </h4>
            : 
              <h4 className='col-12 col-sm-6 py-2 my-auto dark-purple bold'>Car details</h4>
            }
          </div>
          
          <section className="row m-0">
            <div className="col-12 col-lg-5 position-relative p-0 p-md-3 h-500">
              {(car.data && car.data.images && car.data.images.length === 0) || (car.data && !car.data.images) ?
                <>
                  { loadSkeleton ?
                    <div className='w-100 h-100 overflow-hidden'> 
                      <Skeleton variant='rectangle' width={'100%'} height={'75%'}/>
                      <div className='h-25 d-flex pt-2 justify-content-between'>
                        <Skeleton variant='rectangle' width={'24%'} height={'100%'}/>
                        <Skeleton variant='rectangle' width={'24%'} height={'100%'}/>
                        <Skeleton variant='rectangle' width={'24%'} height={'100%'}/>
                        <Skeleton variant='rectangle' width={'24%'} height={'100%'}/>
                      </div> 
                    </div> 
                  : 
                    <img src={imgcarfull} alt="slider" className='w-100 h-100 cover'/>
                  }
                </>
              :
                <> 
                  { loadSkeleton || car.data.images.length === 0 ? 
                    <div className='w-100 h-100 overflow-hidden'> 
                      <Skeleton variant='rectangle' width={'100%'} height={'75%'}/>
                      <div className='h-25 d-flex pt-2 justify-content-between'>
                        <Skeleton variant='rectangle' width={'24%'} height={'100%'}/>
                        <Skeleton variant='rectangle' width={'24%'} height={'100%'}/>
                        <Skeleton variant='rectangle' width={'24%'} height={'100%'}/>
                        <Skeleton variant='rectangle' width={'24%'} height={'100%'}/>
                      </div>
                    </div>  
                  : 
                    <Slider images={car.data.images}/>
                  } 
                </>
              }
            </div>
            <div className="col-12 col-lg-7 my-3 my-md-0 p-md-3 overflow-hidden">
                <div className='row m-0 mb-5'>
                    <h2 className='col-12 mb-4 dark-purple'>Car Information</h2>
                    <div className='col-12 overflow-hidden mb-2'>
                        <span className='coral bold text-uppercase me-2'>Car ID Registration: </span>
                        <span className='dark-purple m-0 word-break'>{car.data.owner}</span>
                    </div>
                    <div className='col-12 overflow-hidden mb-2'>
                        <span className='coral bold text-uppercase me-2'>Registration Date: </span>
                        {/* <span className='dark-purple m-0'>{format(new Date(attributes.regdate), 'dd/MM/yyyy')}</span> */}
                        <span className='dark-purple m-0'>{moment(car.data.date).format('YYYY-MM-DD')}</span>
                    </div>
                    <div className='col-12 overflow-hidden mb-2'>
                        <span className='coral bold text-uppercase me-2'>Transaction ID: </span>
                        <span className='dark-purple m-0 word-break'>
                          <a  className='dark-purple m-0 word-break' href={`${explorer_domain}/tx/${car.tx_hash}/internal-transactions`} rel="noreferrer" target='_blank'>
                              {car.tx_hash}
                          </a>
                        </span>
                    </div>
                    <div className='col-12 overflow-hidden mb-2'>
                        <span className='coral bold text-uppercase me-2'>Country: </span>
                        <span className='dark-purple m-0 word-break'>{car.data.country.label}</span>
                    </div>
                </div>
                <div className='row m-0'>
                    <h2 className='col-12 mb-4 dark-purple'>Owner Information</h2>
                    <div className='col-9 overflow-hidden'>
                        <h6 className='coral bold text-uppercase'>Owner Address</h6>
                        <h6 className='dark-purple m-0 word-break'>{car.data.owner}</h6>
                    </div>
                    <div className='col-3 overflow-hidden d-flex justify-content-end'>
                        <img src={avatarImg} alt="user-avatar" />
                    </div>
                   
                </div>    
            </div>
          </section>

          <section className="row m-0 mt-5">
            <div className="col-12 col-lg-3 col-xl-3">
              { loadSkeleton ? 
                <h4 className='my-auto dark-purple bold mb-4' style={{padding:'2px 0'}}>
                    <Skeleton variant='rectangle' sx={{width:'120px', height:'40px'}} className='p-2'/>
                </h4>
              : 
                <h4 className=' py-2 my-auto dark-purple bold mb-4'>Categories</h4>
              }
              <div className="py-1 row">
                <Accordion setFilters={setFilters} loading={loadSkeleton} documents={filteredDocuments} setCurrentPage={setCurrentPage}/>
              </div>
            </div>
                
            <div className="col-12 col-lg-9 col-xl-9 ps-lg-5">
              
              <div className='row mb-4'>
                { loadSkeleton ? 
                  <h4 className='col-12 col-sm-5 col-md-6 my-auto dark-purple bold px-3' style={{padding:'2px 0'}}>
                      <Skeleton variant='rectangle' sx={{width:'120px', height:'40px'}} className='p-2'/>
                  </h4>
                : 
                  <h4 className='col-12 col-sm-5 col-md-6 py-2 my-auto dark-purple bold'>Documents</h4>
                }

                <div className='col-12 col-sm-7 col-md-6'>
                  
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-12 p-0 overflow-hidden'>
                  <div className='t-head mb-2 p-1'>   
                    <div className='px-2'>
                      {loadSkeleton ? 
                        <Skeleton variant='rectangle' height={44} className='rounded m-0'/> 
                      :
                        <ul className='house-header mb-0'>
                          {houseDetailHeader.map( (header, index) => {
                              // <li key={index} className='house-header-content px-3' style={{width:header.width}}>
                              //     {header.name}
                              // </li>

                              const item = index === 0 ? 
                            <li key={index} className='house-header-content dark-purple px-3' style={{maxWidth:header.width, width:header.width}}>
                                {header.name}
                            </li> :
                            <li key={index} className='house-header-content dark-purple px-3' style={{width:header.width}}>
                                {header.name}
                            </li>
                            return item;
                          })}
                        </ul>
                      }
                    </div>

                    <div className='house-list-wrapper p-2'>
                      {filteredDocuments.length === 0 ?
                        <></>
                      :
                        <> 
                          {loadSkeleton === true || filteredDocuments.length === 0 ?                           
                            <>
                              {filteredDocuments.map((d, index) => (
                                  <Skeleton  key={index} height={110} sx={{ my:-2 }} />
                              ))}
                            </>
                          : 
                            <>
                              {filteredDocuments.map((document, index) => (
                                <ul key={index} className='house-list-item'>
                                  <li className='house-content bg-mas-blue-light' style={{width:'67px', maxWidth:'67px'}}>
                                    <img src={infoImg} alt="QR-car" />
                                  </li>
                                  <li className='house-content word-break ps-4 pe-3 olive justify-content-center' style={{width:'180px'}}>
                                    {/* <span>{document.data.name.replace(/\.[^/.]+$/, "")}</span> */}
                                    <span>{document.metadata.name}</span>
                                  </li>
                                  <li className='house-content word-break px-3' style={{width:'180px'}}>
                                    <span>{document.metadata.description}</span>
                                  </li>
                                  <li className='house-content word-break px-3' style={{width:'180px'}}>
                                    <span>{document.metadata.type}</span>
                                  </li>
                                  <li className='house-content px-3' style={{width:'180px'}}>
                                    <a  className='round-50' href={document.content} target='_blank' download>
                                      <button className='custom-btn btn-light-purple'>Download</button>
                                    </a>
                                  </li> 

                                  <li className='house-content px-3' style={{width:'90px'}}>
                                    <img className='pointer' src={infoIconImg} alt="information" onClick={() => handleShowDoc(document)} />
                                  </li>
                                  
                                  
                                  <li className='house-content px-3' style={{width:'90px'}}>
                                    <i className="fa fa-share-alt fa-lg p-2 pointer" aria-hidden="true" onClick={() => handleShareDoc(document)}></i>
                                  </li>

                                </ul> 
                              ))}
                            </>
                          } 
                        </>
                      } 
                    </div>
                  </div>
                </div>
              </div>                

            </div>
          </section>

          {/* <section className="row mt-3 flex-column-reverse flex-lg-row m-0">
            <div className="col-12 col-lg-3 col-xl-3">
              {loadSkeleton ?
                <Skeleton variant='rectangle' sx={{maxWidth:'150px', height:'40px'}}/>
              :
              <div className='d-flex align-items-center' style={{maxWidth:'150px'}} >
                  <label className="me-3 text-nowrap">Show</label>
                  <select className="form-select" aria-label="select number of rows" value={perPage} onChange={handlePerPage} >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                  </select>
              </div> 
              }
            </div>
            <div className='col-12 col-lg-9 col-xl-9 ps-lg-5 d-flex justify-content-center overflow-hidden mb-4 mb-lg-0'>
              <Pagination totalHouses={documents.length} currentPage={currentPage} perPage={perPage} onPageChange={handlePageChange} />
            </div>
          </section> */}

          <ModalDocumentDetail modalOpened={activeDescDocModal} document={activeDocument} closeModal={() => setActiveDescDocModal(false)} />
          <ModalDocumentShare modalOpened={activeShareDocModal} shareDoc={shareDoc} closeModal={() => setActiveShareDocModal(false)} />
        </main>
      
    </>
  )
}

export default PublicInformation;