import { useContext, useEffect, useRef, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import RingLoader from 'react-spinners/RingLoader';
import SimpleReactValidator from 'simple-react-validator';
import AuthService from '../../services/AuthService';
import { toast } from 'react-toastify';

const Login = () => {    

    const [ email,  setEmail ] = useState("")
    const [ password, setPassword ] = useState("")
    const [ loading, setLoading ] = useState(false)
    const [ isVisible, setVisible ] = useState(false)
    const navigate = useNavigate();
    const Auth = new AuthService();

    const validator = useRef(new SimpleReactValidator({
        element: message => <div style={{color:'#1F0807'}}>{message}</div>
    }));

    useEffect(() => {
        setLoading(false);
        if(Auth.isLoggedIn()) 
            navigate("/cars")
    }, [])

    const handleLogin = (e) => {
        setLoading(true);
        if(!validator.current.allValid()) {
            validator.current.showMessages();
            setLoading(false);
            return;
        }
        Auth.getToken(email, password).then((res) => {
            setLoading(false);
            Auth.setToken(res.data.access);
            Auth.setRefreshToken(res.data.refresh);
            Auth.getProfile().then((prof) => {
                if(prof.data.user.email_verification) {
                    navigate("/cars");
                    toast.success("Login successful.")
                } else {
                    toast.error("Please validate your email address first.");
                    Auth.logout();
                    navigate("/register/confirm");
                }

            })
            .catch((err) => {

            })
            // 
        })
        .catch((err) => {
            setLoading(false);
            toast.error("Username or password not valid.")
        })
    }
    

    return (
        <>
            <Helmet> <title>QR-Car | Login</title> </Helmet>
                {/* EMAIL */}
                <label htmlFor='email' className='form-label'>Email</label>
                <input 
                    type='email' 
                    name='email' 
                    id='email' 
                    className='form-input' 
                    placeholder='yourmail@mail.com' 
                    value={email}
                    onChange={e => {
                        setEmail(e.target.value);
                        validator.current.showMessageFor('email');
                    }}
                />
                <div className='input-error-message'>
                    {validator.current.message('email', email, 'required|email')}
                </div>

                {/* PASSWORD */}
                <label htmlFor='password' className='form-label'>Password</label>
                <div className='position-relative'>
                    <input 
                        type={!isVisible ? 'password' : 'text'} 
                        name='password' 
                        id='password' 
                        className='form-input' 
                        placeholder='* * * * * * * * * * *'
                        value={password}
                        onChange={e => {
                            setPassword(e.target.value);
                            validator.current.showMessageFor('password');
                        }}
                    />
                    <span className='show-password' onClick={() => setVisible(!isVisible)}>
                        {!isVisible ? <i className='fa fa-eye dark' aria-hidden='true'></i> : <i className='fa fa-eye-slash dark' aria-hidden='true'></i> }
                    </span>
                </div>
                <div className='input-error-message'>
                    {validator.current.message('password', password, 'required')}
                </div>

                {/* FORGET PASSWORD */}
                <div className='forget-text'>Did you forget your password?{' '}
                    <Link to={"/password/reset"} className='form-link mas-blue'>Click here</Link> 
                </div>

                <button type='button' onClick={handleLogin} className='form-btn btn-mas-blue-fill' disabled={loading}>
                    {loading ?
                    <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                        <RingLoader color='#1F0807' loading={loading} size={30} />
                    </div>: <>Sign in</>}
                </button>
        </>
    );
}
 
export default Login;