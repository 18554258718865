import React, { useEffect, useRef, useState } from 'react'
import SimpleReactValidator from 'simple-react-validator';
import { RingLoader } from 'react-spinners';
import AuthService from '../../services/AuthService';
import "react-datepicker/dist/react-datepicker.css";
import { readUploadedFile} from '../../utils/getBase64';
import { toast } from 'react-toastify';
import ItemService from '../../services/ItemService';

const ModalAddImage = (props) => {

    const [ images, setImages ] = useState([]);
    const [ encodedImages, setEncodedImages ] = useState([]);
    const [ btnLoading, setBtnLoading ] = useState(false);
    const [ profile, setProfile ] = useState({})

    const validator = useRef(new SimpleReactValidator({
        element: message => <div style={{color:'#ef5350'}}>{message}</div>
    }));

    const Item = new ItemService();
    const Auth = new AuthService();

    useEffect(() => {
        let promises = [];
        promises.push(Auth.getProfile());
        Promise.all(promises).then((results) => {
            setProfile(results[0].data.user);
        }).catch((err) => {
            console.log(err)
        })
    }, []);

    useEffect(() => {
        setEncodedImages(props.images);
        setImages(props.images);
    }, [props.images])

    const handleDeleteImage = (index) => {
        const imgArray = [...images];
        const imgEncodedArray = [...encodedImages];

        const filteredImages = imgArray.filter((img, imgIndex) => imgIndex !== index);
        setImages(filteredImages);

        const filteredEncodedImages = imgEncodedArray.filter((img, imgIndex) => imgIndex !== index);
        setEncodedImages(filteredEncodedImages);
    }

    const handleUploadImage = (e) => {
        const uploadedImages = Array.from(e.target.files);
        const imagesState = [...images];
        const encodedImagesState = [...encodedImages];
        const newImages = [];
        const promises = [];
        if(props.platformConfig.max_allowed_images !== 0 && (imagesState.length + uploadedImages.length) > props.platformConfig.max_allowed_images) {
            toast.error("You reached the maximum amount for images.");
            return;
        }

        uploadedImages.map(uploadedImage => {
            const preventDuplicateImage = imagesState.filter(image => image.name === uploadedImage.name);
            if(preventDuplicateImage.length === 0) {
                promises.push(readUploadedFile(uploadedImage));
                newImages.push(uploadedImage);
            }
        })

        Promise.all(promises).then((results) => {
            // const res = [];
            // results.map(result => res.push(result.binary));
            // setEncodedImages([...encodedImagesState, ...res]);
            setEncodedImages([...encodedImagesState, ...results]);
        });   
        setImages([...imagesState, ...newImages]);
    }

    const handleSubmit = () => {
        if(btnLoading)
            return;
        const data = {
            images: encodedImages
        }
        if(btnLoading)
            return;
        setBtnLoading(true);
        Item.update(props.itemId, {data}).then((res) => {
            toast.success("Car saved.");
            setBtnLoading(false);
            props.closeModal();
            props.reload();            
        })
        .catch((err) => {
            setBtnLoading(false);
            toast.error(err.response.data.message);
        })


    }


    return (
        <>
            { props.modalOpened && <div className='customModal'> 
                <div className='h-100 vw-100 overflow-auto'>
                    <div className='modal-box'>
                        <div className="row pt-1 pb-3 mb-4 border-bottom-light-gray">
                            <div className="col-12 d-flex justify-content-between align-items-center">
                                <h4 className='py-2 my-auto dark-purple bold'>Add new image</h4>
                                <i className="fa fa-times fa-lg pointer p-2" aria-hidden="true" onClick={props.closeModal}></i>
                            </div>
                        </div>

                        <form>
                            <div className="row mb-4 border-bottom-light-gray">

                                {/* IMAGES */}
                                <section className="row m-0">   
                                    <div className="col-12 p-0">
                                        <div className="row m-0">
                                            <label className="col-6 dark-purple bold fs-16 text-uppercase mb-2 p-0"> Images {props.platformConfig.max_allowed_images > 0 && `(max. ${props.platformConfig.max_allowed_images})`}</label>
                                            <div className='col-12 p-0 d-flex flex-wrap'>                             
                                                {images && images.length > 0 &&
                                                    images.map((image, index) => (
                                                        <div key={index} className='m-2' >
                                                            <img className='b-shadow-mid-dark p-2 rounded' style={{width:'120px', height:'120px'}} src={image? image.binary ? image.binary : URL.createObjectURL(image) : null} alt={image? image.name : null}/>
                                                            
                                                            <div  className='text-center my-2'>   
                                                                <i className="fa fa-times fa-lg p-2 pointer" aria-hidden="true" onClick={() => handleDeleteImage(index)}></i>
                                                            </div>
                                                        </div>         
                                                    ))
                                                }
                                            </div>
                                            <div className="col-12 mb-2 p-0">
                                                <div className="custom-file-upload">
                                                    <span>Please choose an image</span>
                                                    <label htmlFor="images" className='custom-btn btn-light-purple pointer d-center' style={{height:'36px'}}> Choose image </label>
                                                    <input 
                                                        type="file" 
                                                        multiple={true}
                                                        accept="image/*"
                                                        name="images" 
                                                        id="images"
                                                        value=''    // only this line solve my problem "Uploading File doesn't let me pick same file after I remove it from selection"
                                                        className="form-input custom-file-upload" 
                                                        placeholder="No file selected"
                                                        onChange={e => handleUploadImage(e)}
                                                    />
                                                </div>
                                                <div className='input-error-message'> </div>    
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                
                            </div>  
                            {/* <div className="row pt-1 ">
                                <div className="col-12 d-flex justify-content-end">
                                    <button className='custom-btn btn-milky-fill' type='submit' >Add Car</button>
                                </div>
                            </div> */}

                            <div className="row pt-1">
                                <div className="col-12 d-flex justify-content-end">
                                    <button className='custom-btn btn-coral' type='button' onClick={handleSubmit}>
                                        {btnLoading ? 
                                            <div className='w-100 h-100 d-center'> 
                                                <RingLoader color='#2E5077' loading={btnLoading} size={25}/> 
                                            </div>  
                                        : 'Add Images'}
                                    </button>
                                </div>
                            </div>


                        </form>   
                    </div>    
                </div>
            </div>}
        </>
    )
}

export default ModalAddImage;