import { useEffect, useState } from 'react';
import { Link, useOutletContext, useParams } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';
import  {Skeleton}  from "@mui/material";
import imgaddcar from '../../assets/images/add_car@2x.png'
import ModalAddCar from './ModalAddCar';
import ItemService from '../../services/ItemService';
import { toast } from 'react-toastify';
import CarsList from './CarsList';
import ModalPackets from './ModalPackets';
import Filter from '../../layouts/Filter';

// import Filter from '../../components/ui/Filter';
// import Modal from '../../components/ui/modal/Modal';
// import Pagination from '../../components/ui/Pagination';

// import CarsList from './CarsList';

const List = () => {

    const Item = new ItemService();    
    
    const [ perPage, setPerPage ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ activeHomeModal, setActiveHomeModal ] = useState(false);
    const [ activePaymentModal, setActivePaymentModal ] = useState(false);
    const [ houses, setHouses ] = useState([]);
    const [ config ] = useOutletContext();
    const [ id, setId ] = useState(0);
    const [ showFilter, setShowFilter ] = useState(false)
    const [ loading, setLoading ] = useState(true);
    const params = useParams();

    useEffect(() => {
        setPerPage(10);
        setCurrentPage(1);
        if (window.location.pathname === '/cars/payment') {
            setActivePaymentModal(true);
        }
        if (window.location.pathname.includes("/cars/add-car/")) {
            setActiveHomeModal(true);
        }
        loadData({
            page: currentPage,
            limit: perPage
        })
        
    }, []);

    const loadData = (params) => {
        Item.index(params).then((res) => {
            setHouses(res.data.items);
            setLoading(false);
        })
        .catch((err) => {
            toast.error("Unable to retrive car list.")
        })
    }

    const applyFilter = (filters) => {
        console.log(filters)
        setCurrentPage(1)
        loadData({
            page: 1,
            limit: perPage,
            ...filters
        })
    }
      
    return (  
        <main>
            <Helmet>
                <title>QR-Car | Cars</title>
            </Helmet>
            { loading ? (
                <>
                    <Skeleton variant='rectangle' height={67} className='rounded' sx={{ margin:'10px 0 20px' }} />
                    <Skeleton variant='rectangle' height={67} className='rounded' sx={{ margin:'10px 0 20px' }} />
                    <Skeleton variant='rectangle' height={67} className='rounded' sx={{ margin:'10px 0 20px' }} />
                    <Skeleton variant='rectangle' height={67} className='rounded' sx={{ margin:'10px 0 20px' }} />
                    <Skeleton variant='rectangle' height={67} className='rounded' sx={{ margin:'10px 0 20px' }} />
                    <Skeleton variant='rectangle' height={67} className='rounded' sx={{ margin:'10px 0 20px' }} />
                    <Skeleton variant='rectangle' height={67} className='rounded' sx={{ margin:'10px 0 20px' }} />
                    <Skeleton variant='rectangle' height={67} className='rounded' sx={{ margin:'10px 0 20px' }} />
                    <Skeleton variant='rectangle' height={67} className='rounded' sx={{ margin:'10px 0 20px' }} />
                    <Skeleton variant='rectangle' height={67} className='rounded' sx={{ margin:'10px 0 20px' }} />  
                </>
            ) : ( <>
                {houses && houses.length === 0 ?
                    <div>
                        <div className='text-center p-2 p-md-5' style={{marginTop:'100px'}}>    
                            <img 
                                src={imgaddcar}
                                alt="Add Car" 
                                className='w-100' 
                                // style={{maxWidth:'574px'}}
                                style={{maxWidth:'300px'}}
                            />  
                            <h3 className='mt-5 coral bold text-uppercase'>There are no vehicles listed</h3>    
                            <p className='dark-chocolate'>Add your first vehicle and start tracking your documents</p>
                            <button className='custom-btn btn-coral' onClick={() => config.payment_enabled ? setActivePaymentModal(true) : setActiveHomeModal(true)}>Add Car</button> 
                        </div>
                        
                        {/* <Modal activeHomeModal={activeHomeModal} activePaymentModal={activePaymentModal} addhouseId={addhouseId} closeModal={closeHousesModal}/> */}
                    </div>
                :
                    <section className='max-container m-auto p-container overflow-auto dark-purple'>
                        <div className='row mb-4 m-0'>
                            <h4 className='col-12 col-sm-5 py-2 my-auto bold'>List of Vehicles</h4>
                            <div className='col-12 col-sm-7 d-flex justify-content-start justify-content-sm-end align-items-center mt-2 mt-sm-0'>
                                <button className={'custom-btn btn-mas-blue-outline'} onClick={() => setShowFilter(!showFilter)}>Filters</button>
                                <Link to='/cars/payment' className='d-inline-block round-50 ms-3' onClick={() => config.payment_enabled ? setActivePaymentModal(true) : setActiveHomeModal(true)}> 
                                    <button className='custom-btn btn-coral'>Add Car</button>
                                </Link>
                                
                            </div>
                        </div>
                        <Filter showFilter={showFilter} applyFilter={applyFilter} />
                        <CarsList cars={houses}/>
                        <div className="d-flex flex-column-reverse flex-sm-row position-relative justify-content-lg-center justify-content-end mt-3 px-2" style={{minHeight:'45px'}}>
                            <div className="select-perPage">
                                {houses && houses.length > 0 && 
                                    <div className='d-inline-flex align-items-center'>
                                        <label className="me-3 d-inline-block text-nowrap ps-2">Show</label>
                                        <select className="form-select" aria-label="select number of rows" onChange={setPerPage} value={perPage}>
                                            <option value="10">10</option>
                                            <option value="15">15</option>
                                            <option value="20">20</option>
                                        </select>
                                    </div>
                                } 
                            </div>
                            <div className='d-flex justify-content-center overflow-hidden mb-2 mb-sm-0 pe-1'>
                                {/* <Pagination totalHouses={filteredHouses.length} currentPage={currentPage} perPage={perPage} onPageChange={handlePageChange} /> */}
                            </div>
                        </div>
                        {/* <Modal activeHomeModal={activeHomeModal} activePaymentModal={activePaymentModal} addhouseId={addhouseId} closeModal={closeHousesModal}/> */}
                    </section>
                }</>
            )}
            <ModalAddCar modalOpened={activeHomeModal} paymentId={params.id} platformConfig={config} closeModal={() => setActiveHomeModal(false)} />
            <ModalPackets modalOpened={activePaymentModal} closeModal={() => setActivePaymentModal(false)} />
        </main>
    );
}
 
export default List;