import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';

import { Helmet } from 'react-helmet-async';
import RingLoader from 'react-spinners/RingLoader';

import { select_style } from '../../layouts/SelectStyle';
import AuthService from '../../services/AuthService';
import SimpleReactValidator from 'simple-react-validator';
import PlatformService from '../../services/PlatformService';
import { toast } from 'react-toastify';

const Register = () => {

    const [ email,  setEmail ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ confirmPassword, setConfirmPassword ] = useState("")
    const [ role, setRole ] = useState(null);
    const [ policy, setPolicy ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ isVisible, setVisible ] = useState(false);
    const [ isVisibleConfirm, setVisibleConfirm ] = useState(false);
    const [ lastName, setLastName ] = useState("");
    const [ firstName, setFirstName ] = useState("");
    const [ rolesOption, setRolesOption ] = useState([]);

    const validator = useRef(new SimpleReactValidator({
        element: message => <div style={{color:'#1F0807'}}>{message}</div>
    }));;

    const navigate = useNavigate();
    const Auth = new AuthService();
    const Platform = new PlatformService();

    const handleRegister = () => {
        setLoading(true);
        if(!validator.current.allValid()) {
            validator.current.showMessages();
            setLoading(false);
            return;
        }
        const data = {
            email: email,
            first_name: firstName,
            last_name: lastName,
            role_id: role.value,
            privacy: policy,
            password: password
        }
        Auth.signup(data).then((res) => {
            setLoading(false);
            toast.success("You successfully signed up.")
            navigate("/register/confirm")
        })
        .catch((err) => {
            setLoading(false);
            toast.error("Signup failed");
        })
    }

    useEffect(() => {
        setLoading(false);
        if(Auth.isLoggedIn())
            navigate("/cars")
        Platform.getRoles().then((res) => {
            setRolesOption(res.data.content.map((e) => ({ value: e.id, label: e.name })))
        })
    }, [])

    return (
        <> 
            <Helmet>
                <title>QR-Car | Register</title>
            </Helmet>
            {/* <form className='form'> */}
                {/* EMAIL */}
                <label htmlFor='email' className='form-label'>Email</label>
                <input 
                    type='email' 
                    name='email' 
                    id='email' 
                    className='form-input' 
                    placeholder='yourmail@mail.com'
                    value={email}
                    onChange={e => {
                        setEmail(e.target.value);
                        validator.current.showMessageFor('email');
                    }} 
                />
                <div className='input-error-message'>
                    {validator.current.message('email', email, 'required|email')}
                </div>

                {/* First Name */}
                <label htmlFor='firstName' className='form-label'>First Name</label>
                <input 
                    type='text' 
                    name='firstName' 
                    id='firstName' 
                    className='form-input' 
                    placeholder='Your First Name Here'
                    value={firstName}
                    onChange={e => {
                        setFirstName(e.target.value);
                        validator.current.showMessageFor('firstName');
                    }} 
                />
                <div className='input-error-message'>
                    {validator.current.message('firstName', firstName, 'required')}
                </div>
                
                {/* Last Name */}
                <label htmlFor='lastName' className='form-label'>Last Name</label>
                <input 
                    type='text' 
                    name='lastName' 
                    id='lastName' 
                    className='form-input' 
                    placeholder='Your Last Name Here'
                    value={lastName}
                    onChange={e => {
                        setLastName(e.target.value);
                        validator.current.showMessageFor('lastName');
                    }} 
                />
                <div className='input-error-message'>
                    {validator.current.message('lastName', lastName, 'required')}
                </div>

                {/* PASSWORD */}
                <label htmlFor='password' className='form-label'>Password</label>
                <div className='position-relative'>
                    <input 
                        type={!isVisible ? 'password' : 'text'} 
                        name='password' 
                        id='password' 
                        className='form-input' 
                        placeholder='* * * * * * * * * * *'
                        value={password}
                        onChange={e => {
                            setPassword(e.target.value);
                            validator.current.showMessageFor('password');
                        }}
                    />
                    <span className='show-password' onClick={() => setVisible(!isVisible)}>
                        {!isVisible ? <i className='fa fa-eye dark' aria-hidden='true'></i> : <i className='fa fa-eye-slash dark' aria-hidden='true'></i> }
                    </span>
                </div>
                <div className='input-error-message'>
                    {validator.current.message('password', password, 'required|min:3')}
                </div>

                {/* CONFIRM PASSWORD */} 
                <label htmlFor='confirm' className='form-label'>Confirm Password</label>
                <div className='position-relative'>
                    <input 
                        type={!isVisibleConfirm ? 'password' : 'text'} 
                        name='confirm password' 
                        id='confirm' 
                        className='form-input' 
                        placeholder='* * * * * * * * * * *'
                        value={confirmPassword}
                        onChange={e => {
                            setConfirmPassword(e.target.value);
                            validator.current.showMessageFor('confirm');
                        }}
                    />
                    <span className='show-password' onClick={() => setVisibleConfirm(!isVisibleConfirm)}>
                        {!isVisibleConfirm ? <i className='fa fa-eye dark' aria-hidden='true'></i> : <i className='fa fa-eye-slash dark' aria-hidden='true'></i> }
                    </span>
                </div>
                <div className='input-error-message'>
                    {validator.current.message('confirm password', confirmPassword, 'required')}
                    {password === confirmPassword ? null :
                        <div style={{color:'#1F0807'}}>Confirm password is not matched</div>
                    }
                </div>
                
                {/* SELECT ROLE */}
                <label htmlFor='role-select' className='form-label'>Select a role</label>
                <Select 
                    id='role-select' 
                    name='role' 
                    isClearable  
                    options={rolesOption} 
                    styles={select_style}
                    value={role}
                    onChange={e => {
                        const role_value = e !== null ? setRole(e) : setRole('')
                        validator.current.showMessageFor('role');
                        return role_value;
                    }}
                />
                <div className='input-error-message'>
                    {validator.current.message('role', role, 'required')}
                </div>
                
                {/* POLICY CHECKBOX */}
                <div style={{marginTop:'40px'}}> 
                    <label className='checkbox-label'>
                        <input 
                            type='checkbox' 
                            id='policy' 
                            name='policy' 
                            className='form-checkbox' 
                            checked={policy}
                            onChange={e => {
                                setPolicy(e.currentTarget.checked);
                                validator.current.showMessageFor('policy');
                            }}
                        />
                        I accept the{' '}
                        <Link to='/terms-conditions' className='form-link mas-blue'>Terms of Use</Link> {` and `}
                        <Link to='/privacy-policy' className='form-link mas-blue'>Privacy Policy.</Link>
                    </label>
                    <div className='input-error-message'>
                        {validator.current.message('policy', policy, 'required|accepted')}
                    </div>
                </div>
                
                {/* SUBMIT BUTTON */}
                <button type='button'  onClick={handleRegister} className='form-btn btn-mas-blue-fill' disabled={loading}>
                    {loading ?
                    <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                        <RingLoader color='#1F0807' loading={loading} size={30} />
                    </div>: <>Register Now</>}
                </button>
            {/* </form> */}

            <p className='fs-18'>Not sure how to create the account? watch our video tutorial from {' '}
                <a href="https://www.youtube.com/embed/Ef73LZzALgs" className='form-link mas-blur' target='_blank'>this link </a> 
            </p>
        </>
    );
}
 
export default Register;